import { useCallback, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { MultiCall } from 'multicall.js';
import Web3 from 'web3'

import { makeContract } from '../../Bridge/utils/contract.utils';

import useWeb3 from '../../../hooks/useWeb3';

import { toBN } from '../../Bridge/utils/formaters.utils';

import { DEFAULT_CHAIN_ID, MULTI_CALL } from '../../../constants/chain'
import spark404Abi from '../../../constants/abis/spark404.json'
import RevenueDistributorAbi from '../../../constants/abis/RevenueDistributor.json'
import { TOKEN_SYMBOL, SPARK404 } from '../../../constants'
import { REVENUE_DISTRIBUTOR } from '../constants/common.constants'

import useGetTokenPrice from '../../../hooks/useGetTokenPrice'



export interface Spark404Types {
  totalSupply: BigNumber | null,
  marketCap: BigNumber | null,
  price: BigNumber| null,
  tokenIds: string[],
  nftClassesById: { [value: string]: string[] }
  availableToClaimTokens:  {
    [value: string]: {
      amount: BigNumber,
      amountUsd: BigNumber,
      symbol: string,
    }
  }
}

const use404Info = () => {
  const [info, setInfo] = useState({
    totalSupply: null,
    marketCap: null,
    price: null,
    tokenIds: null,
    nftClassesById: null,
    availableToClaimTokens: null,
  })
  const { chainId, account, libraryByChainId } = useWeb3()

  const prices = useGetTokenPrice([SPARK404]);

  const get404Info = useCallback(async () => {
    const web3 = new Web3(libraryByChainId(DEFAULT_CHAIN_ID).provider);
    const multiCall = new MultiCall(web3, MULTI_CALL);
    const contract = makeContract(libraryByChainId(DEFAULT_CHAIN_ID), spark404Abi, SPARK404.address);
    const revenueContract = makeContract(libraryByChainId(DEFAULT_CHAIN_ID), RevenueDistributorAbi, REVENUE_DISTRIBUTOR);

    const totalSupply = await contract.methods.totalSupply().call();


    setInfo(prevState => ({
      ...prevState,
      totalSupply: toBN(totalSupply),
      price: prices[TOKEN_SYMBOL.SPARK404],
      marketCap: prices[TOKEN_SYMBOL.SPARK404] ? prices[TOKEN_SYMBOL.SPARK404].times(totalSupply) : null
    }))

    if(!account) {
      return;
    }

    const tokenIdsCount = await contract.methods.ownedCount(account).call();

    const indexes = Array.from(Array(Number(tokenIdsCount)).keys());

    const callsTokensIds = indexes.map((index) => ({
      id: contract.methods.owned(account, index),
    }));

    const [tokensIdsResult] = await multiCall.all([callsTokensIds]);

    const tokensIds = tokensIdsResult.map(item => item.id);

    const [nftClassesResult] = await multiCall.all([
      tokensIds.map((id) => ({
        nftClasses: contract.methods.nftClasses(id),
      })),
    ]);

    const nftClasses =  nftClassesResult.map(item => item.nftClasses);

    const availableToClaimes = await Promise.all(
      tokensIds.map(async (item) => {
        return revenueContract.methods.availableToClaim(item).call();
      })
    );

    const availableToClaimArray = availableToClaimes.flat()

    const nftClassesById = tokensIds.reduce((acc, item, index) => ({
      ...acc, [item]: nftClasses[index]
    }), {});


    const availableToClaimTokensResult = availableToClaimArray.reduce((acc, item) => {
      if (!acc[item[0]]) {
        return { ...acc, [item[0]] : toBN(item[1]) }
      }
      return { ...acc, [item[0]] : toBN(item[1]).plus(acc[item[0]]) }
    }, { })

    const amount = toBN(availableToClaimTokensResult[Object.keys(availableToClaimTokensResult)[0]], SPARK404.decimals);
    const amountUsd = amount.times(prices[TOKEN_SYMBOL.SPARK404])

    setInfo(prevState => ({
      ...prevState,
      tokenIds: tokensIds,
      nftClassesById: nftClassesById,
      availableToClaimTokens: Object.keys(availableToClaimTokensResult)[0]
        ? {
          [Object.keys(availableToClaimTokensResult)[0]]: {
            amount: amount,
            amountUsd: amountUsd,
            symbol: SPARK404.symbol,
          }
        }
        : {},
    }));
  },[chainId, account, prices])

  useEffect(() => {
    if (prices) {
      (async () => {
        await get404Info();
      })()
    }
  }, [chainId, account, prices])

  return {
    update404Info: get404Info,
    info: info,
  };
}

export default use404Info;