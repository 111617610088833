import { makeStyles } from 'tss-react/mui';
import { dFlex } from '../../../../../style/Global'

const useStyles = makeStyles()(() => ({
  img: {
    ...dFlex(),
    width: '100%',
    height: 'auto',
    borderRadius: '4px, 4px, 0px, 0px',
    minHeight: 460,

    '@media (max-width: 640px)': {
      minHeight: 300,
    }
  }
}));

export default useStyles;
