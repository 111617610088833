import React, { FC, PropsWithChildren } from 'react'
import { ConnectorId, Layout as UikitMenu } from 'sparkswap-uikit'
import { links, socials, docs } from './config'
import useWeb3 from '../../hooks/useWeb3'
import { useAuthMethod } from '../../hooks/useAuth'
import { CONNECTOR_ID } from '../../constants/connectors.constants'

const Menu: FC<PropsWithChildren<any>> = (props) => {
  const { account, chainId } = useWeb3()
  const { connectMetaMask, connectWalletConnect, connectCoinbase, logOut } = useAuthMethod()

  const handleLogin = (connectorId: ConnectorId) => {
    switch (connectorId) {
      case CONNECTOR_ID.METAMASK:
        return connectMetaMask()
      case CONNECTOR_ID.WALLET_LINK:
        return connectCoinbase()
      case CONNECTOR_ID.WALLET_CONNECT:
        return connectWalletConnect(chainId)
      default:
        return connectMetaMask()
    }
  }

  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={(connectorId: ConnectorId) => handleLogin(connectorId)}
      logout={logOut}
      socialLinks={socials}
      docs={docs}
      {...props}
    />
  )
}

export default Menu
