import { Token } from "pulsex-sdk";
import { CHAIN_ID } from '../../constants/chain'
import { TOKEN_SYMBOL, TOKENS } from '../../constants';

import DexSwapAbi from '../../constants/abis/DexSwapRouterAbi.json'
import SparkSwapAbi from '../../constants/abis/SparkSwapRouterAbi.json'
import PancakeRouterAbi from '../../constants/abis/PancakeRouterAbi.json'

export enum ROUTER_TYPE {
  DEX_SWAP ,
  SPARK_SWAP,
  PANCAKE,
  FAKE_PLS,
}

export const ROUTERS = {
  [ROUTER_TYPE.DEX_SWAP] : {
    address: '0x6513b561c61d6032D0Ac2dB54739DFaFb8381d62',
    abi: DexSwapAbi,
  },
  [ROUTER_TYPE.SPARK_SWAP] : {
    address: '0x76C08825b4A675FD6a17A244660BabeB4ADA79d5',
    abi: SparkSwapAbi,
  },
  [ROUTER_TYPE.PANCAKE] : {
    address: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    abi: PancakeRouterAbi,
  },
  [ROUTER_TYPE.FAKE_PLS] : {
    address: '0xef2Ac18e7cd0345aAfc98F979c87455f1F9290A6',
    abi: SparkSwapAbi,
  },
}

export const ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS = [ROUTER_TYPE.DEX_SWAP, ROUTER_TYPE.SPARK_SWAP, ROUTER_TYPE.FAKE_PLS]

interface BridgeToken {
  isBridge: boolean;
  payInNative: boolean;
  bridgeTokenId: number;
  fromSwapPath: string[];
  toSwapPath: string[];
  fromRouterType: ROUTER_TYPE;
  toRouterType: ROUTER_TYPE;
}

interface ToTokensMap {
  [chainId: number]: Token[];
}

interface BridgeTokensMap {
  [chainId: number]: {
    [tokenAddress: string]: BridgeToken;
  };
}

interface TokenConfig {
  isNative: boolean;
  toChains: CHAIN_ID[];
  toTokens: ToTokensMap;
  bridgeTokensMap: BridgeTokensMap;
}

type Config = Record<CHAIN_ID, Record<string, TokenConfig>>


/* const TEST_CONFIG = {
  [CHAIN_ID.PULSE_CHAIN]: {
    [TOKEN_SYMBOL.EMP]: {
      toChains: [CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.BSC]: [
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
            isBridge: true,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.FAKE_PLS,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH].address]: {
            isBridge: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH].address
            ],
            fromRouterType: ROUTER_TYPE.FAKE_PLS,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
        }
      }
    },
    [TOKEN_SYMBOL.WETH]: {
      toChains: [CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.BSC]: [
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
            isBridge: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.FAKE_PLS,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH].address]: {
            isBridge: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH].address
            ],
            fromRouterType: ROUTER_TYPE.FAKE_PLS,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
        }
      }
    }

  },
  [CHAIN_ID.BSC]: {
    [TOKEN_SYMBOL.EMP]: {
      toChains: [CHAIN_ID.PULSE_CHAIN],
      toTokens: {
        [CHAIN_ID.PULSE_CHAIN]: [
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.PULSE_CHAIN]: {
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address]: {
            isBridge: true,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.FAKE_PLS,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address]: {
            isBridge: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.FAKE_PLS,
          },
        }
      }
    },
    [TOKEN_SYMBOL.FakeWETH]: {
      toChains: [CHAIN_ID.PULSE_CHAIN],
      toTokens: {
        [CHAIN_ID.PULSE_CHAIN]: [
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.PULSE_CHAIN]: {
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address]: {
            isBridge: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH].address,
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.FAKE_PLS,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address]: {
            isBridge: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.FakeWETH].address,
              TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.FAKE_PLS,
          },
        }
      }
    },
  },
} */

const CONFIG: Config = {
  [CHAIN_ID.BSC]: {
    [TOKEN_SYMBOL.DAI]: {
      isNative: false,
      toChains: [CHAIN_ID.PULSE_CHAIN],
      toTokens: {
        [CHAIN_ID.PULSE_CHAIN] : [
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP],
        ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.PULSE_CHAIN]: {
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address]: {
            isBridge: false,
            payInNative: true,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
        },
      },
    },
    // [TOKEN_SYMBOL.ETH]: {
    //   isNative: false,
    //   toChains: [CHAIN_ID.PULSE_CHAIN],
    //   toTokens: {
    //     [CHAIN_ID.PULSE_CHAIN] : [
    //       TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP],
    //       TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH],
    //       TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI],
    //       TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI],
    //       TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK],
    //       TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS],
    //     ]
    //   },
    //   bridgeTokensMap: {
    //     [CHAIN_ID.PULSE_CHAIN]: {
    //       [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address]: {
    //         isBridge: false,
    //         payInNative: false,
    //         bridgeTokenId: 1,
    //         fromSwapPath: [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address, TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address],
    //         toSwapPath: [],
    //         fromRouterType: ROUTER_TYPE.PANCAKE,
    //         toRouterType: ROUTER_TYPE.SPARK_SWAP,
    //       },
    //       [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address]: {
    //         isBridge: false,
    //         payInNative: false,
    //         bridgeTokenId: 1,
    //         fromSwapPath: [
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
    //         ],
    //         toSwapPath: [
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
    //         ],
    //         fromRouterType: ROUTER_TYPE.PANCAKE,
    //         toRouterType: ROUTER_TYPE.SPARK_SWAP,
    //       },
    //       [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address]: {
    //         isBridge: false,
    //         payInNative: false,
    //         bridgeTokenId: 1,
    //         fromSwapPath: [
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
    //         ],
    //         toSwapPath: [
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
    //         ],
    //         fromRouterType: ROUTER_TYPE.PANCAKE,
    //         toRouterType: ROUTER_TYPE.SPARK_SWAP,
    //       },
    //       [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address]: {
    //         isBridge: false,
    //         payInNative: false,
    //         bridgeTokenId: 1,
    //         fromSwapPath: [
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
    //         ],
    //         toSwapPath: [
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
    //         ],
    //         fromRouterType: ROUTER_TYPE.PANCAKE,
    //         toRouterType: ROUTER_TYPE.SPARK_SWAP,
    //       },
    //       [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address]: {
    //         isBridge: false,
    //         payInNative: false,
    //         bridgeTokenId: 1,
    //         fromSwapPath: [
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
    //         ],
    //         toSwapPath: [
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
    //         ],
    //         fromRouterType: ROUTER_TYPE.PANCAKE,
    //         toRouterType: ROUTER_TYPE.SPARK_SWAP,
    //       },
    //       [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address]: {
    //         isBridge: false,
    //         payInNative: true,
    //         bridgeTokenId: 1,
    //         fromSwapPath: [
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
    //           TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
    //         ],
    //         toSwapPath: [
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
    //           TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address,
    //         ],
    //         fromRouterType: ROUTER_TYPE.PANCAKE,
    //         toRouterType: ROUTER_TYPE.SPARK_SWAP,
    //       },
    //     },
    //   },
    // },
    [TOKEN_SYMBOL.EMP]: {
      isNative: false,
      toChains: [CHAIN_ID.PULSE_CHAIN],
      toTokens: {
        [CHAIN_ID.PULSE_CHAIN] : [
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS],
        ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.PULSE_CHAIN]: {
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath:  [],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath:  [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address]: {
            isBridge: false,
            payInNative: true,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address,
            ],
            fromRouterType: ROUTER_TYPE.PANCAKE,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
        },
      },
    },
  },
  [CHAIN_ID.PULSE_CHAIN]: {
    [TOKEN_SYMBOL.DAI]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM, CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.BSC]: [
          //TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH],
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI]
        ],
        [CHAIN_ID.ARBITRUM]: [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address
          //   ],
          //   toSwapPath: [
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
          //   ],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address
          //   ],
          //   toSwapPath: [],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
        },
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address
            ],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
      },
    },
    [TOKEN_SYMBOL.sDAI]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM, CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.BSC] : [
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH],
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI]
        ],
        [CHAIN_ID.ARBITRUM]: [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address
          //   ],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // }
        },
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address
            ],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
      },
    },
    [TOKEN_SYMBOL.WPLS]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM, CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.ARBITRUM]: [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
        [CHAIN_ID.BSC]: [
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH],
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI]
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address
            ],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WPLS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WPLS].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
          //   ],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WPLS].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
        }
      },
    },
    [TOKEN_SYMBOL.WETH]: {
      isNative: false,
      toChains: [CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.BSC] : [
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI]
        ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address
          //   ],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 2,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
          //   ],
          //   toSwapPath: [],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
        },
      },
    },
    [TOKEN_SYMBOL.SPARK]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM, CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.ARBITRUM]: [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
        [CHAIN_ID.BSC]: [
          //TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH],
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI]
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address
            ],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 2,
            fromSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address,
          //     TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address,
          //   ],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //   ],
          //   toSwapPath: [],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
        }
      },
    },
    [TOKEN_SYMBOL.EMP]: {
      isNative: false,
      toChains: [CHAIN_ID.BSC],
      toTokens: {
        [CHAIN_ID.BSC] : [
          TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP],
          // TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH],
          //TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI],
        ]
      },
      bridgeTokensMap: {
        [CHAIN_ID.BSC]: {
          [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 1,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.PANCAKE,
          },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 1,
          //   fromSwapPath: [],
          //   toSwapPath: [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.EMP].address, TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.ETH].address],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
          // [TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address]: {
          //   isBridge: false,
          //   payInNative: false,
          //   bridgeTokenId: 2,
          //   fromSwapPath: [
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.EMP].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.WETH].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address,
          //     TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
          //   ],
          //   toSwapPath: [],
          //   fromRouterType: ROUTER_TYPE.SPARK_SWAP,
          //   toRouterType: ROUTER_TYPE.PANCAKE,
          // },
        },
      },
    },
    [TOKEN_SYMBOL.USDEX_PLUS]: {
      isNative: false,
      toChains: [CHAIN_ID.ARBITRUM],
      toTokens: {
        [CHAIN_ID.ARBITRUM]: [
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.ARBITRUM]: {
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
          [TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address
            ],
            fromRouterType: ROUTER_TYPE.SPARK_SWAP,
            toRouterType: ROUTER_TYPE.DEX_SWAP,
          },
        },
      },
    },
  },
  [CHAIN_ID.ARBITRUM]: {
    [TOKEN_SYMBOL.USDC]: {
      isNative: false,
      toChains: [CHAIN_ID.PULSE_CHAIN],
      toTokens: {
        [CHAIN_ID.PULSE_CHAIN]: [
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.PULSE_CHAIN]: {
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath:  [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath:  [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address]: {
            isBridge: false,
            payInNative: true,
            bridgeTokenId: 4,
            fromSwapPath: [
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDC].address,
              TOKENS[CHAIN_ID.ARBITRUM][TOKEN_SYMBOL.USDEX_PLUS].address
            ],
            toSwapPath:  [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
        },
      },
    },
    [TOKEN_SYMBOL.USDEX_PLUS]: {
      isNative: false,
      toChains: [CHAIN_ID.PULSE_CHAIN],
      toTokens: {
        [CHAIN_ID.PULSE_CHAIN]: [
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK],
          TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS],
        ],
      },
      bridgeTokensMap: {
        [CHAIN_ID.PULSE_CHAIN]: {
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address]: {
            isBridge: true,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.DAI].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address]: {
            isBridge: false,
            payInNative: false,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
          [TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address]: {
            isBridge: false,
            payInNative: true,
            bridgeTokenId: 4,
            fromSwapPath: [],
            toSwapPath: [
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.USDEX_PLUS].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.sDAI].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.SPARK].address,
              TOKENS[CHAIN_ID.PULSE_CHAIN][TOKEN_SYMBOL.PLS].address
            ],
            fromRouterType: ROUTER_TYPE.DEX_SWAP,
            toRouterType: ROUTER_TYPE.SPARK_SWAP,
          },
        },
      },
    },
  },
}


// TODO: change on production on CONFIG
// export default TEST_CONFIG;

// TODO: PROD
export default CONFIG;
