import { Pair, CurrencyAmount, Currency, JSBI } from 'sparkswap-sdk'
import { useMemo } from 'react'
import { Interface } from '@ethersproject/abi'

import DexSwapPair from '../../constants/abis/DexSwapPair.json'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import useWeb3 from '../../hooks/useWeb3'

const PAIR_INTERFACE = new Interface(DexSwapPair)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useWeb3()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [tokens]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')
  const fees = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'fee');
  const protocolShares = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'protocolShare');

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading: reservesLoading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]
      const { loading: loadingFees, result: feeRaw } = fees[i];
      const { loading: loadingProtocolShares, result: protocolSharesRaw } = protocolShares[i];
      const loading = reservesLoading || loadingFees || loadingProtocolShares;

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      const protocolShare = JSBI.BigInt(protocolSharesRaw![0]);
      const swapFee = JSBI.BigInt(feeRaw![0]);
      const currencyAmountA = CurrencyAmount.fromRawAmount(token0, reserve0.toString());
      const currencyAmountB = CurrencyAmount.fromRawAmount(token1, reserve1.toString());
      return [
        PairState.EXISTS,
        new Pair(currencyAmountA, currencyAmountB, swapFee, protocolShare)
      ]
    })
  }, [results, tokens, fees, protocolShares])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}
