import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { ResetCSS } from 'sparkswap-uikit'
import 'inter-ui'
import ConnectionContext from 'contexts/ConnectionContext'
import GlobalStyle from './style/Global'
import App from './pages/App'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import Providers from './Providers'
import './i18n'

if ('ethereum' in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false
}

window.addEventListener('error', () => {
  localStorage?.removeItem('redux_localstorage_simple_lists')
})

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <ConnectionContext>
        <Providers>
          <>
            <ListsUpdater />
            <ApplicationUpdater />
            <TransactionUpdater />
            <MulticallUpdater />
          </>
          <ResetCSS />
          <GlobalStyle />
          <App />
        </Providers>
      </ConnectionContext>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
