// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL0 = 'pancakeswap'
export const DEFAULT_TOKEN_LIST_URL1 = 'sparkswap'
// export const DEFAULT_TOKEN_LIST_URL2 = 'tokens.uniswap.eth'
// export const DEFAULT_TOKEN_LIST_URL3 = 'https://tokens.app.pulsex.com'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL0,
  DEFAULT_TOKEN_LIST_URL1,
  // DEFAULT_TOKEN_LIST_URL2,
  // DEFAULT_TOKEN_LIST_URL3
]
