import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  loader: {
    width: '210px',
    height: '45px',
  },
}));

export default useStyles;
