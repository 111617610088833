import React, { FC, PropsWithChildren, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Popups from '../components/Popups'
import Bridge from './Bridge/bridge'
import Spark404 from './Spark404'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Menu from '../components/Menu'
import {
  RedirectDuplicateTokenIds as RedirectDuplicateTokenIdsNew,
  RedirectOldAddLiquidityPathStructure as RedirectOldAddLiquidityPathStructureNew,
  RedirectToAddLiquidity as RedirectToAddLiquidityNew,
} from '../new/pages/AddLiquidity/redirects'
import AddLiquidityNew from '../new/pages/AddLiquidity'
import SwapNew from '../new/pages/Swap'
import PoolNew from '../new/pages/Pool'
import PoolFinderNew from '../new/pages/PoolFinder'
import RemoveLiquidityNew from '../new/pages/RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure as RedirectOldRemoveLiquidityPathStructureNew } from '../new/pages/RemoveLiquidity/redirects'
import { SPARK_404_URL } from '../components/Menu/config'
import 'react-loading-skeleton/dist/skeleton.css';
import Timer from '../components/Timer'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
`

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  flex: 1;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  position: relative;
  padding: 0 15px 100px;
  z-index: 19;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 30px;
  }
`

const BgLeft = styled.div`
  position: absolute;
  left: -50%;
  top: -150px;
  z-index: -1;
`

const BgRight = styled.div`
  position: absolute;
  right: -40%;
  top: 200px;
  z-index: -1;
  pointer-events: none;
`

const Page: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Wrapper>
      <Container>
        <BgRight>
          <img src="/images/bgRight.png" alt="bg" width={950} />
        </BgRight>
        <BgLeft>
          <img src="/images/bgLeft.png" alt="bg" width={950} />
        </BgLeft>
        <BodyWrapper>{children}</BodyWrapper>
      </Container>
    </Wrapper>
  )
}

export const TIMER = '1716319913'

export default function App() {
  return (
    <>
      <Timer remainTime={TIMER} />
      <Menu>
        <Suspense fallback={null}>
          <Page>
            <Popups />
            <Switch>
              <Route exact strict path="/swap" component={SwapNew} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
              <Route exact strict path="/bridge" component={Bridge} />
              <Route exact strict path={SPARK_404_URL} component={Spark404} />
              <Route exact strict path="/find" component={PoolFinderNew} />
              <Route exact strict path="/pool" component={PoolNew} />
              <Route exact strict path="/create" component={RedirectToAddLiquidityNew} />
              <Route exact path="/add" component={AddLiquidityNew} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructureNew} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIdsNew} />
              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructureNew} />
              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidityNew} />
              <Route component={RedirectPathToSwapOnly} />
            </Switch>
          </Page>
        </Suspense>
      </Menu>
    </>
  )
}
