import { Token } from 'pulsex-sdk'
import BigNumber from 'bignumber.js'

import CONFIG from '../config'
import { TOKENS } from '../../../constants'
import { CHAIN_ID } from '../../../constants/chain'

import { BridgeState } from '../types/bridge'

export const getDataByToken = (token: Token, fromChain: number, activeToChainId: number, tokenTo: Token | null) => {
  const toToken = CONFIG[fromChain][token.symbol]

  const { toTokens, toChains } = toToken

  const currentToChain = toChains.includes(activeToChainId) ? activeToChainId : toChains[0]
  const finalToTokens = toTokens[currentToChain]
  const currentToToken = finalToTokens.includes(tokenTo) ? tokenTo : finalToTokens[0]

  return {
    to: {
      chain: currentToChain,
      chains: toChains,
      token: currentToToken,
      tokens: finalToTokens,
    },
  }
}

export const getDataByChainId = (fromChain: CHAIN_ID) => {
  const chains = Object.keys(CONFIG).map(Number)
  const currentDataObj = CONFIG[fromChain]

  const fromTokenSymbols = Object.keys(currentDataObj)
  const fromTokens: Token[] = fromTokenSymbols.map((item) => TOKENS[fromChain][item])
  const fromToken = fromTokens[0]
  const { symbol: symbolFrom } = fromToken

  const defaultToken = currentDataObj[symbolFrom || '']
  const { toChains } = defaultToken
  const currentToChain = toChains[0]
  const currentToTokens = defaultToken.toTokens[currentToChain]

  return {
    from: {
      chain: fromChain,
      chains,
      token: fromTokens[0],
      tokens: fromTokens,
    },
    to: {
      chain: currentToChain,
      chains: toChains,
      token: currentToTokens[0],
      tokens: currentToTokens,
    },
  }
}

export const switchDataByChainId = (from: BridgeState, to: BridgeState) => {
  const _fromChainActive = to.chain
  const _fromChains = Object.keys(CONFIG).map(Number)
  const _fromObj = CONFIG[_fromChainActive]
  const _fromTokenSymbols = Object.keys(_fromObj)
  const _fromTokens: Token[] = _fromTokenSymbols.map((item) => TOKENS[_fromChainActive][item])
  const _fromToken = to.token && _fromTokens.includes(to.token) ? to.token : _fromTokens[0]
  const _fromTokenSymbol = _fromToken.symbol || ''

  const _toChain = from.chain
  const { toChains, toTokens } = _fromObj[_fromTokenSymbol]
  const _toTokens = toTokens[_toChain]

  const _toToken = from.token && _toTokens.includes(from.token) ? from.token : _toTokens[0]

  return {
    from: {
      chain: _fromChainActive,
      chains: _fromChains,
      token: _fromToken,
      tokens: _fromTokens,
    },
    to: {
      chain: _toChain,
      chains: toChains,
      token: _toToken,
      tokens: _toTokens,
    },
  }
}

export const calculateFormattedAmount = (amount: string, decimals: number) => {
  return amount && new BigNumber(amount).div(new BigNumber(10).pow(decimals)).toFormat(2)
}

export const convertToWei = (inputValue: string, decimals: number | undefined) => {
  if (!inputValue || !decimals) {
    return '';
  }

  return new BigNumber(inputValue).times(new BigNumber(10).pow(decimals))
}
