import React, { FC, memo, useMemo } from 'react'

import { useTimer } from 'react-timer-hook'

import { Heading, useMatchBreakpoints } from 'sparkswap-uikit'

import Digit from './Digit'

import { timeSecondToDate } from '../../utils/date'

import useStyles from './styles.module'


interface Props {
  remainTime: string;
}

const Timer: FC<Props> = ({ remainTime }) => {
  const { classes } = useStyles();
  const state = useMatchBreakpoints()


  const time = useMemo(() => {
    if (!remainTime) return null;

    return timeSecondToDate(Number(remainTime));
  }, [remainTime]);

  const { minutes, hours, days } = useTimer({
    expiryTimestamp: time,
    autoStart: true,
    onExpire: () => console.warn('onExpire called'),
  });

  return (
    <div className={classes.banner}>
      <Heading
        size={state.isSm || state.isXs ? 'sm' : 'md'}
        className={classes.heading}
      >
        Spark404 launch is LIVE!
      </Heading>

      {/*<div className={classes.box}>*/}
      {/*  <div className={classes.timerContainer}>*/}
      {/*    {days !== undefined ? <Digit value={days} title='days' /> : null}*/}
      {/*    &nbsp;<Heading size={state.isSm || state.isXs ? 'sm' : 'md'}>:</Heading>&nbsp;*/}
      {/*    <Digit value={hours} title='hrs' />*/}
      {/*    &nbsp;<Heading size={state.isSm || state.isXs ? 'sm' : 'md'}>:</Heading>&nbsp;*/}
      {/*    <Digit value={minutes} title='mins' />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>

  );
};

export default memo(Timer);
