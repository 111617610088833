import React, { FC, memo, useCallback } from 'react'
import { Heading, Text } from 'sparkswap-uikit'

import Modal from 'components/Modal'

import { Column, Selectable } from '../../styles'

import { CHAIN_ICON, CHAIN_ID, CHAIN_NAME } from '../../../../constants/chain'

import { BridgeData } from '../../types/bridge'

interface ChainChangeModalProps {
  isOpen: boolean
  onDismiss: () => void
  title: string
  data: BridgeData
  onClearInput: (value: string) => void
}

const ChainChangeModal: FC<ChainChangeModalProps> = ({ isOpen, onDismiss, title, data, onClearInput }) => {

  const handleChainSelect = useCallback(async (chainId: CHAIN_ID) => {
    await data.onChangeChain(chainId)
    onDismiss()
    onClearInput('');
  }, [])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={40} minHeight={20}>
      <Column>
        <Heading marginBottom="1rem">{title}</Heading>
        {data.chains.map((chainId) => {
          if (chainId === data.chain) return null
          return (
            <Selectable onClick={() => handleChainSelect(chainId)} key={chainId}>
              <img src={CHAIN_ICON[chainId]} alt="chain logo" width={25} style={{ marginRight: '8px' }} />
              <Text>{CHAIN_NAME[chainId]}</Text>
            </Selectable>
          )
        })}
      </Column>
    </Modal>
  )
}

export default memo(ChainChangeModal)
