import React, { ReactNode } from 'react'
import { Trade, TradeType } from 'sparkswap-sdk'
import {  CardBody, Text } from 'sparkswap-uikit'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'
import { StyledCard } from '../../../components/swap/styleds'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade<any, any, any>; allowedSlippage: number }) {
  const { priceImpactWithoutFee, realizedLPFee, totalFeePercent } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  return (
    <StyledCard>
      <CardBody >
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{isExactIn ? 'Minimum received' : 'Maximum sold'}</Text>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px" color='secondaryBright'>
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                '-'}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">Price Impact</Text>
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">Liquidity Provider Fee</Text>
            <QuestionHelper text={`For each trade a ${totalFeePercent?.toSignificant(4)}% liquidity provider fee is incurred`} />
          </RowFixed>
          <Text fontSize="14px" color='secondaryBright'>
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </RowBetween>
      </CardBody>
    </StyledCard>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade<any, any, any>
}

function SwapRouteWrapper({ trade }: { trade: Trade<any, any, any> }) {
  const SwapRouteElement = SwapRoute as (props: { trade: Trade<any, any, any> }) => ReactNode | any
  return <SwapRouteElement trade={trade} />
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)


  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <AutoColumn>

                <SwapRoute trade={trade} />
                {/* <SwapRouteWrapper trade={trade} /> */}
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
