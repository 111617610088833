import React, { FC, useMemo, memo } from 'react'
import { Card, Heading, Text } from 'sparkswap-uikit'
import styled from 'styled-components'

import ImageWithFallback from '../../../../../components/ImageWithFallback'

import { Spark404Types } from '../../../hooks/use404Info'

import nftMetaData from '../../../constants/nftMetaData.json';
import { NFT_CLASS_NAME } from '../../../constants/common.constants'

import useStyles from './styles.module';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  gap: 16px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

interface Props {
  id: string,
  spark404Info: Spark404Types,
}

const NftCard: FC<Props> = ({id, spark404Info}) => {
  const { classes } = useStyles();

  const nftData = useMemo(() => (
    nftMetaData[id]
  ),[id]);

  const cid = useMemo(() => (
    nftData ? nftData.image.split('//')[1] : ''
  ),[nftData]);

  const nftClass = useMemo(() => (
    spark404Info.nftClassesById[id]
  ),[spark404Info, id]);

  return (
    <Card>
      <ImageWithFallback
        cid={cid}
        className={classes.img}
      />
      <Content>
        <Row>
          <Heading>ID</Heading>
          <Heading>{nftData.name}</Heading>
        </Row>

        <Row>
          <Text fontSize="20px">Rewards:</Text>

          <List>
            {nftClass.map((text) => (
              <Text fontSize="20px" color="secondaryBright" key={text} bold>
                {NFT_CLASS_NAME[text]}
              </Text>
            ))}
          </List>
        </Row>
      </Content>
    </Card>
  )
}

export default memo(NftCard);
