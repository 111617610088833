import React, { useCallback, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button, Card, Text } from 'sparkswap-uikit'

import { ApprovalState, useApproveCallback } from '../../../../hooks/useApproveCallback'
import { useBalance } from '../../../../new/state/wallet/hooks'
import useWeb3 from '../../../../hooks/useWeb3'
import useExchange from '../../hooks/useExchange'

import tryParseAmount from '../../../../state/bridge/hooks'
import { applyDecimals, formatBNWithCommas, formatBN } from '../../../Bridge/utils/formaters.utils'

import { dFlex } from '../../../../style/Global'

import Row, { AutoRow } from 'components/Row'
import Loader from '../../../../components/Loader'
import PageHeader from '../../../../components/PageHeader'
import CoinLogo from '../../../../components/pancake/CoinLogo'
import NumericalInput from '../../../../components/NumericalInput'
import ConnectWalletButton from '../../../../components/ConnectWalletButton'

import { B_SPARK404, SPARK, SPARK404 } from '../../../../constants'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  height: 100%;
  justify-content: space-between;
`

export const FromBox = styled.div`
  padding: 24px;
  border-radius: 32px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  gap: 24px;
  display: flex;
  flex-direction: column;
`

export const EstimateBox = styled.div`
  padding: 24px;
  border-radius: 32px;
  background: #0A0434;
  gap: 24px;
  display: flex;
  flex-direction: column;
`

const DESCRIPTION =
  'To buy Spark404 you need to exchange Spark for bSpark, whereby your Spark tokens will be burned.' +
  ' Or you can buy Spark404 directly using WPLS'

const Exchange = () => {
  const { account } = useWeb3()
  const [inputValue, setInputValue] = useState('')

  const { onExchange, loading } = useExchange()

  const { balance: sparkBalance, updateBalance: updateBalanceSpark } = useBalance(SPARK.address)
  const { balance: bCetoBalance, updateBalance: updateBalanceBSpark } = useBalance(B_SPARK404.address)


  const parsedTokenFromAmount = useMemo(() => tryParseAmount(inputValue, SPARK), [inputValue])

  const [approval, approveCallback] = useApproveCallback(parsedTokenFromAmount, B_SPARK404.address)

  const showApproveFlow = useMemo(() => approval !== ApprovalState.APPROVED && inputValue, [approval, inputValue])

  const normalizedCetoBalance = useMemo(
    () => (account ? `Balance: ${formatBNWithCommas(sparkBalance, 4, SPARK.decimals)}` : '0.00'),
    [account, sparkBalance])

  const normalizedBCetoBalance = useMemo(
    () => (account ? `Balance: ${formatBNWithCommas(bCetoBalance, 4, B_SPARK404.decimals)}` : '0.00'),
    [account, bCetoBalance])

  const valueToBurnBN = useMemo(() => applyDecimals(inputValue, SPARK.decimals), [inputValue])

  const isDisabled = useMemo(
    () => valueToBurnBN.isNaN() || valueToBurnBN.gt(sparkBalance) || valueToBurnBN.isZero(),
    [valueToBurnBN, sparkBalance],
  )

  const handleChange = useCallback((value: string) => {
    setInputValue(value)
  }, [])

  const handleApprove = async () => {
    await approveCallback()
  }

  const handleMax = () => {
    if (!account || sparkBalance.isZero()) return
    setInputValue(formatBN(sparkBalance, SPARK.decimals, SPARK.decimals))
  }
  const handleBurn = async () => {
    await onExchange(inputValue)
    await updateBalanceSpark()
    await updateBalanceBSpark()
    setInputValue('')
  }

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <>
          <Card marginTop={30} style={{ zIndex: 2, ...dFlex('space-between', 'initial'), flexDirection: 'column' }}>
            <PageHeader title="Spark to bSpark Burner" description={DESCRIPTION} hideSettings hideHistory />
            <Content>
              <FromBox>
                <Row justifyContent={'space-between'}>
                  <Text color={theme?.colors.textSubtle} fontSize="14px">
                    From
                  </Text>
                  <Text color={theme?.colors.textSubtle} fontSize="14px">
                    {normalizedCetoBalance}
                  </Text>
                </Row>
                <Row>
                  <NumericalInput className="token-amount-input" value={inputValue} onUserInput={handleChange} />

                  {account && (
                    <Button onClick={handleMax} size="sm" variant="text">
                      <Text color="secondaryBright" bold fontSize="14px">
                        MAX
                      </Text>
                    </Button>
                  )}
                  <CoinLogo size="18px" srcs={['/images/coins/SPARK.png']} />
                  <Text paddingLeft="6px">{SPARK.symbol}</Text>
                </Row>
              </FromBox>

              <EstimateBox>
                <Row justifyContent={'space-between'}>
                  <Text color={theme?.colors.textSubtle} fontSize="14px">
                    Burn to {B_SPARK404.symbol}
                  </Text>
                  <Text color={theme?.colors.textSubtle} fontSize="14px">
                    {normalizedBCetoBalance}
                  </Text>
                </Row>
                <Row>
                  <NumericalInput
                    fontSize="24px"
                    readOnly
                    className="token-amount-input"
                    value={inputValue}
                    color="#1F17F7"
                  />
                  <>
                    <CoinLogo size="18px" srcs={['/images/coins/BSPARK.png']} />
                    <Text paddingLeft="6px">{B_SPARK404.symbol}</Text>
                  </>
                </Row>
              </EstimateBox>

              {account ? (
                <>
                  {showApproveFlow ? (
                    <Button
                      fullWidth
                      onClick={handleApprove}
                      disabled={isDisabled || approval === ApprovalState.PENDING}
                      variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          Approving <Loader stroke="white" />
                        </AutoRow>
                      ) : approval === ApprovalState.APPROVED ? (
                        'Approved'
                      ) : (
                        `Approve ${SPARK404.symbol}`
                      )}
                    </Button>
                  ) : (
                    <Button onClick={handleBurn} fullWidth disabled={isDisabled} isLoading={loading}>
                      <AutoRow gap="6px" justify="center">
                        Burn to {B_SPARK404.symbol} {loading && <Loader stroke="white" />}
                      </AutoRow>
                    </Button>
                  )}
                </>
              ) : (
                <ConnectWalletButton fullWidth />
              )}
            </Content>
          </Card>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default Exchange
