import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, ETHER, JSBI, Pulse, Token, Trade } from 'pulsex-sdk'


// try to parse a user entered amount for a given token
function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount<Token> | CurrencyAmount<Pulse> | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency.isToken
        ? CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.fromRawAmount(ETHER, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.info(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export default tryParseAmount;