import React, { createContext, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import cw3p from 'create-web3-provider'
import { Connector } from '@web3-react/types'
import { BaseProvider, Web3Provider } from '@ethersproject/providers'

import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { useWeb3React, Web3ContextType } from '@web3-react/core'

import { useConnection } from '../../hooks/useConnection'

import { ethersWeb3ProviderFactory } from './custom.web3-provider'

import { walletConnectV2Connection } from '../../connectors/connectors'
import { AVAILABLE_CHAINS, CHAIN_ID, CHAINS_RPC, DEFAULT_CHAIN_ID } from '../../constants/chain'
import { ConnectionType } from '../../constants/connectors.constants'

export const chainIdToHex = (chainId: CHAIN_ID): string => `0x${Number(chainId).toString(16)}`

type Web3ContextTypeWithoutProvider<T extends BaseProvider = Web3Provider> = Omit<Web3ContextType<T>, 'provider'>

type Web3ReactContextInterface = Web3ContextTypeWithoutProvider & {
  library: any
  libraryByChainId?: (chainId: number) => any
  activate: (connector: Connector, id?: number) => Promise<void>
  deactivate: () => void
  changeChain: (chainId: CHAIN_ID) => Promise<void>
  chainId?: CHAIN_ID
  loading: boolean
}

export const Web3Context = createContext<Web3ReactContextInterface>({} as Web3ReactContextInterface)

interface Props {
  children: ReactNode
}

const Web3ContextProvider: FC<Props> = ({ children }) => {
  const { provider: library, chainId, account, connector: connectorWeb3, ...web3React } = useWeb3React()

  const { connectionInStorage } = useConnection()

  const [state, setState] = useState({
    chainId: DEFAULT_CHAIN_ID,
    library,
  })
  const [loading, setLoading] = useState(false)

  // timer for getting to some time for checking wallet connection
  // set default provider if no wallet connects after timeout
  const connectTimer = useRef<NodeJS.Timeout>()

  const { ethereum } = window as any

  useEffect(() => {
    // don't do anything if smth is loading(connecting/disonnecting)
    if (loading) return

    if (chainId) {
      if (AVAILABLE_CHAINS.includes(chainId)) {
        if (connectTimer.current) clearTimeout(connectTimer.current)
        setState({
          chainId,
          library: _.cloneDeep(library),
        })
      } else {
        setState({
          chainId: undefined,
          library: undefined,
        })
      }
    } else {
      if (connectTimer.current) clearTimeout(connectTimer.current)
      connectTimer.current = setTimeout(() => {
        // TODO redundant check?
        if (!chainId && !account) {
          const providerLink = cw3p({ uri: CHAINS_RPC[Number(DEFAULT_CHAIN_ID)][0] })
          const web3Provider = new Web3Provider(providerLink)
          setState({
            chainId: DEFAULT_CHAIN_ID,
            library: web3Provider,
          })
          setLoading(false)
        }
      }, 300)
    }

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(connectTimer.current)
  }, [chainId, loading, library])

  const libraryByChainId = (id) => {
    return ethersWeb3ProviderFactory(id)
  }

  useEffect(() => {
    if (ethereum) {
      ethereum.once('chainChanged', () => {
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        }, 0)
      })
    }
  }, [ethereum, chainId])

  const changeChain = useCallback(
    async (id: CHAIN_ID) => {
      if (account && ethereum) {
        setLoading(true)
        try {
          if (connectionInStorage === ConnectionType.INJECTED) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainIdToHex(id) }],
            })
          }

          await connectorWeb3.activate(id)
        } catch (err: any) {
          if (err.code === 4902) {
            if (connectionInStorage === ConnectionType.INJECTED) {
              await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainIdToHex(id) }],
              })
            }
          } else {
            console.log(err, 'err')
          }
        } finally {
          setLoading(false)
        }
      }
    },
    [ethereum, account],
  )

  const activateHandler = useCallback(async (connector: Connector, id?: number) => {
    setLoading(true)
    try {
      if (connector instanceof WalletConnectV2) {
        await connector.activate(id)
      } else {
        await connector.activate()
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const deactivateHandler = useCallback(() => {
    setLoading(true)
    try {
      if (connectorWeb3?.deactivate) {
        // eslint-disable-next-line no-void
        void connectorWeb3.deactivate()
      } else {
        // eslint-disable-next-line no-void
        void connectorWeb3.resetState()
        walletConnectV2Connection.connector.deactivate()
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Web3Context.Provider
      value={{
        ...web3React,
        connector: connectorWeb3,
        loading,
        libraryByChainId,
        library: account && AVAILABLE_CHAINS.includes(chainId) ? state.library : libraryByChainId(DEFAULT_CHAIN_ID),
        chainId: state.chainId,
        changeChain,
        activate: activateHandler,
        deactivate: deactivateHandler,
        account,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3ContextProvider
