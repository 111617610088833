import BigNumber from 'bignumber.js'

import { makeBridgeContract, makeContract } from '../utils/contract.utils'

import { ZERO_ADDRESS } from '../../../constants'
import CONFIG, { ROUTERS, ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS } from '../config'

import { applyDecimals, toBN } from '../utils/formaters.utils'

import { BridgeData, BridgeDataFrom } from '../types/bridge'
import useWeb3 from '../../../hooks/useWeb3'

export type EstimateParams = {
  amount: string
  from: BridgeDataFrom
  to: BridgeData
}

type useBridgeActions = {
  onEstimate: (param: EstimateParams) => Promise<BigNumber>
}

const useBridgeActions = (): useBridgeActions => {
  const { libraryByChainId } = useWeb3()

  const onEstimate = async ({ amount, from, to }: EstimateParams) => {
    try {
      if (!amount || !from.token?.symbol || !to.chain || !to.token?.address) return toBN(0)

      const bridgeContract = makeBridgeContract(libraryByChainId(from.chain), from.chain)

      const formatAmount = applyDecimals(amount, from.token.decimals).toFixed(0)

      const bridgeTokenToConfig = await bridgeContract.methods.bridgeTokenToChainConfig(from.bridgeTokenId, to.chain).call()

      const divider = await bridgeContract.methods.DIVIDER().call()

      const fromObj = CONFIG[from.chain][from.token?.symbol]
      const fromBridgeToken = fromObj.bridgeTokensMap[to.chain][to.token?.address]
      const fromRouterConfig = ROUTERS[fromBridgeToken.fromRouterType]

      const { fromSwapPath, toSwapPath, fromRouterType, toRouterType } = fromBridgeToken

      let fromAmount = formatAmount

      if (fromSwapPath.length) {
        const fromRouterContract = makeContract(libraryByChainId(from.chain), fromRouterConfig.abi, fromRouterConfig.address)

        if (ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS.includes(fromRouterType)) {
          const result = await fromRouterContract.methods.getAmountsOut(formatAmount, fromSwapPath, ZERO_ADDRESS).call()
          fromAmount = result[result.length - 1]
        } else {
          const result = await fromRouterContract.methods.getAmountsOut(formatAmount, fromSwapPath).call()
          fromAmount = result[result.length - 1]
        }
      }

      const fee = toBN(1).minus(toBN(bridgeTokenToConfig.fee).div(divider))

      const bridgeAmount = toBN(fromAmount).times(fee).minus(bridgeTokenToConfig.feeBase)

      const formatBridgeAmount = bridgeAmount.toFixed(0)

      if (bridgeAmount.lt(0)) {
        return toBN(0)
      }

      let toAmount

      if (toSwapPath.length) {
        const toRouterConfig = ROUTERS[toRouterType]
        const toRouterContract = makeContract(libraryByChainId(to.chain), toRouterConfig.abi, toRouterConfig.address)

        if (ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS.includes(toRouterType)) {
          const result = await toRouterContract.methods.getAmountsOut(formatBridgeAmount, toSwapPath, ZERO_ADDRESS).call()
          toAmount = result[result.length - 1]
        } else {
          const result = await toRouterContract.methods.getAmountsOut(formatBridgeAmount, toSwapPath).call()
          toAmount = result[result.length - 1]
        }

        return toBN(toAmount, to.token.decimals)
      }

      return toBN(formatBridgeAmount, to.token.decimals)
    } catch (e) {
      console.error('Error in onEstimate:', e)
      return toBN(0);
    }
  }

  return {
    onEstimate,
  }
}

export default useBridgeActions
