import React from 'react'
import styled from 'styled-components'
import { escapeRegExp } from '../../utils'

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string, color?: string }>`
    color: ${({ error, theme, color }) => (error ? theme.colors.failure : color || theme.colors.secondaryBright)};
    width: 0;
    position: relative;
    font-family: 'Orbitron';
    font-weight: 700;
    outline: none;
    border: none;
    flex: 1 1 auto;
    background-color: transparent;
    font-size: ${({ fontSize }) => fontSize ? fontSize : '16px'};
    text-align: ${({ align }) => align && align};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px;
    -webkit-appearance: textfield;
    appearance: textfield;

    ::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    [type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    ::placeholder {
        color: ${({ theme, color }) => color || theme.colors.secondaryBright};
    }
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUserInput?: (input: string) => void
  error?: boolean
  fontSize?: string
  color?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <StyledInput
      {...rest}
      value={value}
      onChange={event => {
        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  )
})

export default Input
