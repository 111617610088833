import type { AddEthereumChainParameter } from '@web3-react/types'

import PulseIcon from '../assets/images/pulse-logo.png'
import BscIcon from '../assets/images/bsc-logo.png'
import ArbitrumIcon from '../assets/images/arbitrum.svg'

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }


export enum CHAIN_ID {
  BSC = 56,
  PULSE_CHAIN = 369,
  ARBITRUM = 42161,
}

export const DEFAULT_CHAIN_ID = CHAIN_ID.PULSE_CHAIN;

export const AVAILABLE_CHAINS = [CHAIN_ID.BSC, CHAIN_ID.PULSE_CHAIN, CHAIN_ID.ARBITRUM];

export const CURRENT_BRIDGE_CHAIN_ID = 'bridge_chain_id';
export const DEFAULT_BRIDGE_CHAIN = CHAIN_ID.BSC;


const PULSE: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Pulse',
  symbol: 'PLS',
  decimals: 18,
}

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Binance Coin',
  symbol: 'BNB',
  decimals: 18,
}

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

export const CHAINS_RPC = {
  [CHAIN_ID.BSC]: ['https://bsc.publicnode.com', 'https://1rpc.io/bnb', 'https://rpc.ankr.com/bsc', 'https://bsc-pokt.nodies.app', 'https://bsc-dataseed.binance.org'],
  [CHAIN_ID.ARBITRUM]: ['https://arbitrum-one.publicnode.com', 'https://arb-pokt.nodies.app', 'https://1rpc.io/arb', 'https://rpc.ankr.com/arbitrum'],
  [CHAIN_ID.PULSE_CHAIN]: ['https://rpc.pulsechain.com', 'https://rpc-pulsechain.g4mm4.io', 'https://pulsechain.publicnode.com'],
};

export const MAINNET_CHAINS: ChainConfig = {
  [CHAIN_ID.PULSE_CHAIN]: {
    urls: CHAINS_RPC[CHAIN_ID.PULSE_CHAIN],
    name: 'PulseChain',
    nativeCurrency: PULSE,
    blockExplorerUrls: ['https://scan.pulsechain.com'],
  },
  [CHAIN_ID.ARBITRUM]: {
    urls: CHAINS_RPC[CHAIN_ID.ARBITRUM],
    name: 'Arbitrum',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io/'],
  },
  [CHAIN_ID.BSC]: {
    urls: CHAINS_RPC[CHAIN_ID.BSC],
    name: 'BNB Chain',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com'],
  },
}

export const TESTNET_CHAINS: ChainConfig = {
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  }
  return chainId
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)

export const CHAIN_NAME = {
  [CHAIN_ID.BSC]: 'BSC',
  [CHAIN_ID.PULSE_CHAIN]: 'Pulse',
  [CHAIN_ID.ARBITRUM]: 'Arbitrum',
}

export const CHAIN_ICON = {
  [CHAIN_ID.BSC]: BscIcon,
  [CHAIN_ID.PULSE_CHAIN]: PulseIcon,
  [CHAIN_ID.ARBITRUM]: ArbitrumIcon,
}

export const GAS_MULTIPLIER = {
  [CHAIN_ID.PULSE_CHAIN]: 1.5,
};

export const GAS_PRICE_MULTIPLIER = {
  [CHAIN_ID.PULSE_CHAIN]: 1.1,
};

export const MULTI_CALL = '0x5d2c170751dc8ca5cfda91158274e55eae5f6e39';
