import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle


export const dFlex = (justifyContent?: string, alignItems?: string) => ({
  display: 'flex',
  justifyContent: justifyContent || 'center',
  alignItems: alignItems || 'center',
});

