import Web3 from 'web3'
import { Contract } from '@ethersproject/contracts'
import { WETH } from 'pulsex-sdk'
import IUniswapV2PairABI from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import {BRIDGE_ABI, BRIDGE_ADDRESS_ARBITRUM, BRIDGE_ADDRESS_BSC, BRIDGE_ADDRESS_PULSE} from 'constants/abis/bridge'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { getContract } from '../utils'
import { CHAIN_ID } from "../constants/chain";
import useWeb3 from './useWeb3'

export const makeContract = <T = any>(library: any, abi: any, address: string): T => {
  const web3 = new Web3(library.provider)
  return new web3.eth.Contract(abi, address) as unknown as T
}

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library: provider, account } = useWeb3()

  return useMemo(() => {
    if (!address || !ABI || !provider) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account])
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useWeb3()
  return useContract(chainId ? V1_FACTORY_ADDRESSES[chainId] : undefined, V1_FACTORY_ABI, false)
}

export const BRIDGE_ADDRESSES = {
  [CHAIN_ID.BSC]: BRIDGE_ADDRESS_BSC,
  [CHAIN_ID.PULSE_CHAIN]: BRIDGE_ADDRESS_PULSE,
  [CHAIN_ID.ARBITRUM]: BRIDGE_ADDRESS_ARBITRUM
}

export function useBridgeContract(): Contract | null {
  const { chainId } = useWeb3()
  return useContract(chainId ? BRIDGE_ADDRESSES[chainId] : null, BRIDGE_ABI, true)
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3()
  return useContract(chainId && WETH[chainId] ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3()
  let address: string | undefined
  if (chainId) {
    switch (Number(chainId)) {
    case 369:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI.abi, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useWeb3()
  return useContract(chainId ? MULTICALL_NETWORKS[chainId] : undefined, MULTICALL_ABI, false)
}

export function makeMulticallContract(provider: any, chainId: CHAIN_ID): Contract | null {
  return makeContract(provider, MULTICALL_ABI, MULTICALL_NETWORKS[chainId])
}

export function useSocksController(): Contract | null {
  const { chainId } = useWeb3()
  return useContract(
    Number(chainId) === 369 ? '0x3D38F97628117864f50dCB15323C4E949F516B94' : undefined,
    UNISOCKS_ABI,
    false
  )
}
