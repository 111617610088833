import React, { useEffect, useRef } from 'react'
import { useCountUp } from 'react-countup'
import { Heading } from 'sparkswap-uikit'

interface CardValueProps {
  value: number
  decimals?: number
  size?: string
  color?: string
  prefix?: string
}

const CardValue: React.FC<CardValueProps> = ({ value, decimals, prefix, size = 'xl', color }) => {
  const countUpRef = useRef(null);
  const { update } = useCountUp({
    start: 0,
    end: value,
    duration: 1,
    separator: ' ',
    decimals:
      // eslint-disable-next-line no-nested-ternary
      decimals !== undefined ? decimals : value < 0 ? 4 : value > 1e5 ? 0 : 3,
    startOnMount: true,
    ref: countUpRef,
    enableReinitialize: true,
    prefix,
  })

  const updateValue = useRef(update)

  useEffect(() => {
    updateValue.current(value)
  }, [value, updateValue])

  return (
    <Heading ref={countUpRef} color={color || 'secondaryBright'} size={size} >
      {/* {prefix} */}
      {updateValue.toString()}
    </Heading>
  )
}

export default CardValue
