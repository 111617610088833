import styled from 'styled-components'

const Pane = styled.div`
  border: 1px solid rgba(184, 182, 203, 1);
  border-radius: 16px;
  padding: 16px;
    width: 100%;
`

export default Pane
