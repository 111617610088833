import React from 'react'
import { Card, Heading, Text } from 'sparkswap-uikit'
import styled from 'styled-components'
import { SPARK404 } from '../../../../constants'


const DATA = [
  {
    text: 'Spark404 unveils an exclusive collection of 10,000 unique NFTs, organized into four unique classifications that embody varying levels of NFT reflections.'
  },
  {
    text: 'Each category is characterized by a distinct color of Sparky the Robot, the emblematic mascot of Spark Swap. Beyond color variations, each NFT features additional rarity traits that enhance its potential value in secondary markets.'
  },
  {
    text: 'Collectors will find diverse and potentially more valuable NFTs within each category, making each piece a sought-after asset.',
    isAccent: true
  }
]



export const Header = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

`

export const Logo = styled.img`
  width: 48px;
  height: 48px;
`

const Info = () => {
  return (
    <Card padding={24} marginTop={30} style={{ zIndex: 2 }}>
      <Header>
        <Logo src={'/images/coins/SPARK404.png'} />
        <Heading size='lg'>{SPARK404.symbol}</Heading>
      </Header>

      <Content>
        {DATA.map(({text, isAccent}) => (
          <Text fontSize='16px' bold={isAccent} color={isAccent ? '#26FFFE' : '#E9E9E9'} key={text}>{text}</Text>
        ))}
      </Content>
    </Card>
  )
}

export default Info
