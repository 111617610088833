import { JSBI, Percent, Token, WETH } from 'pulsex-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import type { Connector } from '@web3-react/types'
import { CHAIN_ID } from './chain';
import priceContracts from './eggPriceContracts'

export enum TOKEN_SYMBOL {
  USDEX_PLUS = 'USDEX+',
  USDC = 'USDC',
  EMP = 'EMP',
  DAI = 'DAI',
  WBNB = 'WBNB',
  BNB = 'BNB',
  BUSD = 'BUSD',
  sDAI = 'sDAI',
  FakeWETH = 'FakeWETH', // TODO TEST SYMBOL
  WETH = 'WETH',
  ETH = 'ETH',
  SPARK = 'SPARK',
  PLS = 'PLS',
  WPLS = 'WPLS',
  SPARK404 = 'Spark404',
  B_SPARK = 'bSpark',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const SPARK_ADDRESS = priceContracts.sparkAddress;

export const EMP_ADDRESSES = {
  [CHAIN_ID.BSC]: '0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
  [CHAIN_ID.PULSE_CHAIN]: '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
}

export const DAI_ADDRESSES = {
  [CHAIN_ID.BSC]: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  [CHAIN_ID.PULSE_CHAIN]: '0x30FCB23A906493371b1721C8feb8815804808D74',
}

export const USDEX_ADDRESSES = {
  [CHAIN_ID.ARBITRUM]: '0x4117EC0A779448872d3820f37bA2060Ae0B7C34B',
  [CHAIN_ID.PULSE_CHAIN]: '0xaA2C47a35C1298795B5271490971Ec4874C8e53d',
}

const USDC_ADDRESSES = {
  [CHAIN_ID.BSC]: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  [CHAIN_ID.PULSE_CHAIN]: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
}

export enum ROUTER {
  SPARKSWAP,
  PULSEX,
  PANCAKESWAP,
}

export const ROUTER_ADDRESS = {
  [ROUTER.PANCAKESWAP]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ROUTER.SPARKSWAP]: '0x76C08825b4A675FD6a17A244660BabeB4ADA79d5',
  [ROUTER.PULSEX]: '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02',
}

export const FACTORY_ADDRESS = {
  [ROUTER.PANCAKESWAP]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ROUTER.SPARKSWAP]: '0xE9e8Ad10AEa5965243406Cb88d5174C8503B1030',
  [ROUTER.PULSEX]: '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02',
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in number | number]: Token[]
}

export const SPARK = new Token(CHAIN_ID.PULSE_CHAIN, SPARK_ADDRESS, 18, 'SPARK', 'Spark Token')
export const DAI = new Token(CHAIN_ID.PULSE_CHAIN, '0xefD766cCb38EaF1dfd701853BFCe31359239F305', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(CHAIN_ID.PULSE_CHAIN, USDC_ADDRESSES[CHAIN_ID.PULSE_CHAIN], 6, 'USDC', 'USD Coin')
export const USDT = new Token(CHAIN_ID.PULSE_CHAIN, '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f', 18, 'USDT', 'Tether USD')
export const PLSX = new Token(CHAIN_ID.PULSE_CHAIN, '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab', 18, 'PLSX', 'PulseX')
export const ETH = new Token(CHAIN_ID.PULSE_CHAIN, '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C', 18, 'ETH', 'Ethereum Token')
export const GOAT = new Token(CHAIN_ID.PULSE_CHAIN, '0xF5D0140B4d53c9476DC1488BC6d8597d7393f074', 18, 'GOAT', 'Degen Protocol')
export const BRO = new Token(CHAIN_ID.PULSE_CHAIN, '0x401464296A7e0cd14D85AB6baF0Dc91B5ad5Ad1b', 18, 'BRO', 'BROmance')
export const SDAI = new Token(CHAIN_ID.PULSE_CHAIN, '0x30fcb23a906493371b1721c8feb8815804808d74', 18, TOKEN_SYMBOL.sDAI, 'sDAI')

export const SPARK404 = new Token(CHAIN_ID.PULSE_CHAIN, '0xDc2e74740279dD1ed27727eddf80aF7CcC3D6a72', 18, TOKEN_SYMBOL.SPARK404, 'Spark404')
export const B_SPARK404 = new Token(CHAIN_ID.PULSE_CHAIN, '0xFcff9ddd271c8dc3A00d6F7e9C9cfE0FF80E3732', 18, TOKEN_SYMBOL.B_SPARK, 'Burned Spark')

export const PATH_TO_STABLE = {
  [TOKEN_SYMBOL.SPARK404]: [SPARK404.address, B_SPARK404.address, WETH[CHAIN_ID.PULSE_CHAIN].address, SPARK.address, SDAI.address, DAI.address],
}

export const EMP_TOKEN = {
  [CHAIN_ID.BSC]: new Token(CHAIN_ID.BSC, EMP_ADDRESSES[CHAIN_ID.BSC], 18, 'EMP', 'emp.money'),
  [CHAIN_ID.PULSE_CHAIN]: new Token(CHAIN_ID.PULSE_CHAIN, EMP_ADDRESSES[CHAIN_ID.PULSE_CHAIN], 18, 'EMP', 'EMP from Binance'),
}

export const DAI_TOKEN = {
  [CHAIN_ID.BSC]: new Token(CHAIN_ID.BSC, DAI_ADDRESSES[CHAIN_ID.BSC], 18, 'DAI', 'Dai Token'),
  [CHAIN_ID.PULSE_CHAIN]: new Token(CHAIN_ID.PULSE_CHAIN, DAI_ADDRESSES[CHAIN_ID.PULSE_CHAIN], 18, 'sDAI', 'sDAI'),
}

export const USDEX_TOKEN = {
  [CHAIN_ID.ARBITRUM]: new Token(CHAIN_ID.ARBITRUM, USDEX_ADDRESSES[CHAIN_ID.ARBITRUM], 18, 'USDEX+', 'USDEX+'),
  [CHAIN_ID.PULSE_CHAIN]: new Token(CHAIN_ID.PULSE_CHAIN, USDEX_ADDRESSES[CHAIN_ID.PULSE_CHAIN], 18, 'USDEX+', 'USDEX+'),
}


const WETH_ONLY: ChainTokenList = {
  [CHAIN_ID.PULSE_CHAIN]: [WETH[CHAIN_ID.PULSE_CHAIN]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [CHAIN_ID.PULSE_CHAIN]: [WETH[CHAIN_ID.PULSE_CHAIN], PLSX, SPARK, DAI, USDC, USDT, ETH, DAI_TOKEN[CHAIN_ID.PULSE_CHAIN], USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN], EMP_TOKEN[CHAIN_ID.PULSE_CHAIN], BRO, SPARK404, B_SPARK404]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in number]?: { [tokenAddress: string]: Token[] } } = {
  [CHAIN_ID.PULSE_CHAIN]: {
    // [ETH.address]: [DAI, WETH[CHAIN_ID.PULSE_CHAIN], EMP_TOKEN[CHAIN_ID.PULSE_CHAIN]],
    // [EMP_ADDRESSES[CHAIN_ID.PULSE_CHAIN]]: [ETH],
    // [USDEX_ADDRESSES[CHAIN_ID.PULSE_CHAIN]]: [DAI_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI, SPARK, WETH[CHAIN_ID.PULSE_CHAIN], ETH, EMP_TOKEN[CHAIN_ID.PULSE_CHAIN]],
    // [DAI_TOKEN[CHAIN_ID.PULSE_CHAIN].address]: [USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI, SPARK, WETH[CHAIN_ID.PULSE_CHAIN], ETH, EMP_TOKEN[CHAIN_ID.PULSE_CHAIN]],
    // [SPARK_ADDRESS[CHAIN_ID.PULSE_CHAIN]]: [USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI, WETH[CHAIN_ID.PULSE_CHAIN], ETH, EMP_TOKEN[CHAIN_ID.PULSE_CHAIN]],
  },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [CHAIN_ID.PULSE_CHAIN]: [SPARK, DAI_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI, ETH, EMP_TOKEN[CHAIN_ID.PULSE_CHAIN],  USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [CHAIN_ID.PULSE_CHAIN]: [WETH[CHAIN_ID.PULSE_CHAIN], DAI, USDC, USDT, ETH, SPARK, DAI_TOKEN[CHAIN_ID.PULSE_CHAIN], USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN], EMP_TOKEN[CHAIN_ID.PULSE_CHAIN]],
}

export const PINNED_PAIRS: { readonly [chainId in number]?: [Token, Token][] } = {
  [CHAIN_ID.PULSE_CHAIN]: [
    [
      SPARK,
      WETH[CHAIN_ID.PULSE_CHAIN],
    ],
    [
      ETH,
      EMP_TOKEN[CHAIN_ID.PULSE_CHAIN]
    ],
    [GOAT, SPARK],
    [DAI_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI],
    [USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN], DAI_TOKEN[CHAIN_ID.PULSE_CHAIN]],
    [SPARK, DAI_TOKEN[CHAIN_ID.PULSE_CHAIN]],
    [ETH, DAI],
  ],
}

export interface WalletInfo {
  connector?: AbstractConnector | Connector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}


export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(22)) // 10,000 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

const getInfuraUrlFor = (network: string) =>
  process.env.infuraKey ? `https://${network}.infura.io/v3/${process.env.infuraKey}` : undefined
const getAlchemyUrlFor = (network: string) =>
  process.env.alchemyKey ? `https://${network}.alchemyapi.io/v2/${process.env.alchemyKey}` : undefined

export const TOKENS = {
  [CHAIN_ID.ARBITRUM]: {
    [TOKEN_SYMBOL.USDEX_PLUS]: new Token(
      CHAIN_ID.ARBITRUM,
      '0x4117EC0A779448872d3820f37bA2060Ae0B7C34B',
      18,
      TOKEN_SYMBOL.USDEX_PLUS,
      'USDEX+',
    ),
    [TOKEN_SYMBOL.USDC]: new Token(
      CHAIN_ID.ARBITRUM,
      '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      6,
      TOKEN_SYMBOL.USDC,
      'USD Coin',
    ),
  },
  [CHAIN_ID.BSC]: {
    // TODO PROD EMP
    [TOKEN_SYMBOL.EMP]: new Token(
      CHAIN_ID.BSC,
      '0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
      18,
      TOKEN_SYMBOL.EMP,
      'emp.money'
    ),
    // TODO remove TEST EMP
    // [TOKEN_SYMBOL.EMP]: new Token(
    //   CHAIN_ID.BSC,
    //   '0xF80De11e82141a20acA1657899dE18b68dF1085C',
    //   18,
    //   TOKEN_SYMBOL.EMP,
    //   'FakeWETH'
    // ),
    // TODO PROD ETH
    [TOKEN_SYMBOL.ETH]: new Token(
      CHAIN_ID.BSC,
      '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      18,
      TOKEN_SYMBOL.ETH,
      'Ethereum Token'
    ),
    // TODO remove TEST FakeWETH
    // [TOKEN_SYMBOL.FakeWETH]: new Token(
    //   CHAIN_ID.BSC,
    //   '0x5AFa3556483c56B6a15033914a2c40479a4B28e4',
    //   18,
    //   TOKEN_SYMBOL.FakeWETH,
    //   'FakeWETH'
    // ),
    [TOKEN_SYMBOL.DAI]: new Token(
      CHAIN_ID.BSC,
      '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      18,
      TOKEN_SYMBOL.DAI,
      'Dai Token'
    ),
    [TOKEN_SYMBOL.WBNB]: new Token(
      CHAIN_ID.BSC,
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      18,
      TOKEN_SYMBOL.WBNB,
      'Wrapped BNB'
    ),
    [TOKEN_SYMBOL.BNB]: new Token(
      CHAIN_ID.BSC,
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      18,
      TOKEN_SYMBOL.BNB,
      'Binance Coin'
    ),
    [TOKEN_SYMBOL.BUSD]: new Token(
      CHAIN_ID.BSC,
      '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      18,
      TOKEN_SYMBOL.BUSD,
      'Binance-Peg BUSD'
    ),
  },
  [CHAIN_ID.PULSE_CHAIN]: {
    // TODO PROD EMP
    [TOKEN_SYMBOL.EMP]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0x9231937aC31506B6913ac5fb1DB5a1C1AE83783A',
      18,
      TOKEN_SYMBOL.EMP,
      'EMP from Binance'
    ),
    // TODO remove TEST EMP
    // [TOKEN_SYMBOL.EMP]: new Token(
    //   CHAIN_ID.PULSE_CHAIN,
    //   '0x8661C5a1834E45b1825B8942DDDf251D07b458d6',
    //   18,
    //   TOKEN_SYMBOL.EMP,
    //   'FakeEMP'
    // ),
    // TODO PROD WETH
    [TOKEN_SYMBOL.WETH]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c',
      18,
      TOKEN_SYMBOL.WETH,
      'Wrapped Ether from Ethereum'
    ),
    // TODO remove TEST WETH
    /* [TOKEN_SYMBOL.WETH]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0xD9f7c35f3d24936CfA06aD0be5669AA9C24cB41c',
      18,
      TOKEN_SYMBOL.WETH,
      'FakeWETH'
    ), */
    [TOKEN_SYMBOL.USDEX_PLUS]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0xaA2C47a35C1298795B5271490971Ec4874C8e53d',
      18,
      TOKEN_SYMBOL.USDEX_PLUS,
      'USDEX+'
    ),
    [TOKEN_SYMBOL.sDAI]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0x30FCB23A906493371b1721C8feb8815804808D74',
      18,
      TOKEN_SYMBOL.sDAI,
      'sDAI'
    ),
    [TOKEN_SYMBOL.DAI]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
      18,
      TOKEN_SYMBOL.DAI,
      'Dai Stablecoin'
    ),
    [TOKEN_SYMBOL.SPARK]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0x6386704cD6f7A584EA9D23cccA66aF7EBA5a727e',
      18,
      TOKEN_SYMBOL.SPARK,
      'Sparkswap'
    ),
    [TOKEN_SYMBOL.PLS]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
      18,
      TOKEN_SYMBOL.PLS,
      'Wrapped Pulse'
    ),
    [TOKEN_SYMBOL.WPLS]: new Token(
      CHAIN_ID.PULSE_CHAIN,
      '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
      18,
      TOKEN_SYMBOL.WPLS,
      'Wrapped Pulse'
    ),
  }
}
