import MULTICALL_ABI from './abi.json'
import {CHAIN_ID} from "../chain";

const MULTICALL_NETWORKS: { [chainId: number]: string } = {
  [CHAIN_ID.BSC]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [CHAIN_ID.PULSE_CHAIN]: '0xAA8BE262d44fa4D583d05635e08eAcEdbb311584',
  [CHAIN_ID.ARBITRUM]: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
