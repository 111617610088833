export const DATE_FORMAT = {
  YEAR_MONTH_DATE: 'YYYY/MM/DD',
  YEAR_MONTH_HOURS_MINUTES_DATE: 'DD.MM.YYYY HH:mm',
  DAY_MONTH_YEAR_TIME: 'DD.MM.YYYY HH:mm',
  DAY_MONTH_YEAR_FULL_TIME: 'DD MMM YYYY HH:mm',
  EPOCH_TIME: 'DD.MM.YYYY, HH:mm',
  DAY_MONTH: 'D MMMM',
  TIME: 'HH:mm',
  DAY_SHORT_MONTH: 'DD/MM',
  DAY_SHORT_MONTH_DOT: 'DD.MM',
  FULL_DATE: 'DD.MM.YYYY'
};

