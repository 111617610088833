import React, { FC, useMemo, useState, useCallback } from 'react'
import { Button, Heading, useMatchBreakpoints } from 'sparkswap-uikit'
import styled from 'styled-components'

import NftCard from './NftCard'
import { Spark404Types } from '../../hooks/use404Info'

export const Box = styled.div`
  margin-top: 30px;
`

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
`

export const ButtonWrap = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`


interface Props {
  spark404Info: Spark404Types,
}

const MyNfts: FC<Props> = ({ spark404Info }) => {
  const [step, setStep] = useState(9);
  const state = useMatchBreakpoints()

  const tokenIds = useMemo(() => (
    spark404Info.tokenIds ? spark404Info.tokenIds.slice(0, step) : []
  ),[spark404Info, step]);

  const handleMore = useCallback(() => {
    setStep(prevState => prevState + 9)
  },[step])

  return (
    <Box>
      {
        spark404Info.tokenIds && !!spark404Info.tokenIds.length && (
          <Heading size={state.isXl || state.isXxl ? 'xl' : 'lg'} marginBottom={24} style={{ textAlign: 'center' }}>
            My NFTs
          </Heading>
        )
      }
      <List>
        {
          tokenIds.map((item) => (
            <NftCard key={item} id={item} spark404Info={spark404Info} />
          ))
        }
      </List>
      {
        spark404Info.tokenIds && tokenIds.length !== spark404Info.tokenIds.length && (
          <ButtonWrap>
            <Button
              onClick={handleMore}
            >
              Show More
            </Button>
          </ButtonWrap>
        )
      }
    </Box>
  )
}

export default MyNfts
