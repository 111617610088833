import React, { useMemo, FC } from 'react'
import Card from './Card'
import styled from 'styled-components'

import { toBN } from '../../../Bridge/utils/formaters.utils'

import { SPARK404 } from '../../../../constants'
import { Spark404Types } from '../../hooks/use404Info'


export const STATS_ITEMS = [
  {
    title: 'Total allocation',
    keyValue: 'totalAllocation',
    decimals: 0,
    prefix: undefined,
  },
  {
    title: 'Market cap',
    keyValue: 'marketCap',
    decimals: 0,
    prefix: '$',
  },
  {
    title: 'Price',
    keyValue: 'price',
    decimals: 0,
    prefix: '$',
  },
]

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  width: 100%;
  z-index: 2;


  ${({ theme }) => theme.mediaQueries.sm} {
      grid-template-columns: repeat(3, 1fr);
  }
`


interface Props {
  spark404Info: Spark404Types,
}


const Stats: FC<Props> = ({ spark404Info}) => {

  const stats = useMemo(
    () => ({
      totalAllocation: spark404Info.totalSupply ? toBN(spark404Info.totalSupply, SPARK404.decimals).toString() : '0',
      marketCap: spark404Info.marketCap ? toBN(spark404Info.marketCap, SPARK404.decimals) : '0',
      price: spark404Info.price ? toBN(spark404Info.price) : '0',
    }), [spark404Info])

  return (
    <Box>
      {STATS_ITEMS.map((item) => (
        <Card {...item} key={item.keyValue} value={stats[item.keyValue]} />
      ))}
    </Box>
  )
}

export default Stats
