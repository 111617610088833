import { useCallback, useState } from 'react'

// import showToast from '../../../components/Toast'

import useWeb3 from '../../../hooks/useWeb3'
import { makeContract } from '../../../hooks/useContract'
import { web3SendTxWrap } from '../../../utils'

import { REVENUE_DISTRIBUTOR } from '../constants/common.constants'
import RevenueDistributorAbi from '../../../constants/abis/RevenueDistributor.json'
// import { TOAST_TYPE } from '../../../constants/toast.constants'


const useClaim = () => {
  const [loading, setLoading] = useState(false)
  const { library, chainId, account } = useWeb3()

  const claim = useCallback(async (ids: string[]) => {
    try {
      setLoading(true)
      const contract = makeContract(library, RevenueDistributorAbi, REVENUE_DISTRIBUTOR);

      const recipient = await web3SendTxWrap(
        contract,
        'claim',
        [ids],
        { from: account },
        library,
        chainId,
      )

      // showToast(
      //   {
      //     title: `Claimed`,
      //     withLink: {
      //       chainId: chainId,
      //       hash: recipient.transactionHash,
      //     },
      //   },
      //   TOAST_TYPE.SUCCESS,
      //   {
      //     showProgressBar: true
      //   }
      // )
    } catch (e) {
      console.log('e', e)
      // showToast(
      //   {
      //     title: `Denied Transaction`,
      //   },
      //   TOAST_TYPE.ERROR,
      //   {
      //     showProgressBar: true
      //   }
      // )
    } finally {
      setLoading(false)
    }
  },[library, chainId])

  return {
    loading,
    claim,
  }
}

export default useClaim;