import { Currency } from '@pulsex/sdk-core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import {Token} from "pulsex-sdk";
import EthereumLogo from '../../assets/images/PLS.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'
import {EMP_TOKEN, SPARK_ADDRESS, USDEX_TOKEN} from '../../constants'
import { CHAIN_ID } from "../../constants/chain";
import UsdexIcon from '../../assets/images/USDEX+.svg'

const getTokenLogoURL = (address: string) => {
  switch (address.toLowerCase()) {
  case EMP_TOKEN[CHAIN_ID.BSC].address.toLowerCase(): case EMP_TOKEN[CHAIN_ID.PULSE_CHAIN].address.toLowerCase(): // EMP
    return `https://fleek.ipfs.io/ipfs/bafybeifg7t5crr4fbpemtig526dxqxugtqlp4u4idesvrez5xmsfoytkqy`;
  case USDEX_TOKEN[CHAIN_ID.ARBITRUM].address.toLowerCase(): case USDEX_TOKEN[CHAIN_ID.PULSE_CHAIN].address.toLowerCase(): // EMP
    return UsdexIcon
  case SPARK_ADDRESS.toLowerCase():
    return `https://sparkswap.xyz/images/spark.png`;
  default:
    return `https://tokens.app.pulsex.com/images/tokens/${address}.png`;
      // return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`
  }
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency | Token
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return []

    if (currency?.isToken) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, `/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address)]
      }

      return [`/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency?.isNative) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  return (currency as any)?.symbol ? (
    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
