import React, { useCallback, FC, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Card, Heading, Text, useMatchBreakpoints } from 'sparkswap-uikit'
import BigNumber from 'bignumber.js'

import SkeletonLoader from '../../../../components/SkeletonLoader';

import { dFlex } from '../../../../style/Global'
import useWeb3 from '../../../../hooks/useWeb3'

import useClaim from '../../hooks/useClaim'

import { Spark404Types } from '../../hooks/use404Info'
import { formatBNWithCommas, toBN } from '../../../Bridge/utils/formaters.utils'
import QuestionHelper from '../../../../components/QuestionHelper'

import useStyles from './styles.module';



export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-bottom: 32px;
`


export const TextSymbol = styled.div`
  color: ${({ theme }) => theme.colors.secondaryBright};
`

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`


interface Props {
  spark404Info: Spark404Types,
  update404Info: () => Promise<void>
}

const Claim: FC<Props> = ({ spark404Info , update404Info}) => {
  const state = useMatchBreakpoints();
  const { account } = useWeb3()
  const { classes } = useStyles()

  const { claim, loading} = useClaim();

  const sumAmountUsd = useMemo(() => (
    spark404Info.availableToClaimTokens
      ? Object.keys(spark404Info.availableToClaimTokens).reduce((acc, item) => (
        acc.plus(spark404Info.availableToClaimTokens[item].amountUsd)
      ), toBN(0))
      : toBN(0)
  ), [spark404Info])

  const handleClaim = useCallback(async () => {
    await claim(spark404Info.tokenIds);
    await update404Info();
  }, [spark404Info.tokenIds])

  return (
    <Card
      padding={24}
      marginTop={30}
      style={{ zIndex: 2, ...dFlex('space-between', 'initial'), flexDirection: 'column' }}
    >

      <Content>
        <Text fontSize='24px'>Your 404 rewards</Text>
        {
          !spark404Info.availableToClaimTokens && account
            ? <SkeletonLoader className={classes.loader}/>
            : (
              <Heading
                color='secondaryBright' size={state.isXl || state.isXxl ? 'xl' : 'lg'}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                ${formatBNWithCommas(sumAmountUsd, 4, 0, true)}
                {
                  !sumAmountUsd.isZero() && (
                    <QuestionHelper text={<ClaimValues availableToClaimTokens={spark404Info.availableToClaimTokens}/>}/>
                  )
                }
              </Heading>
            )
        }
      </Content>

      <div>
        <Button
          onClick={handleClaim}
          fullWidth
          isLoading={loading}
          disabled={sumAmountUsd.isZero()}
        >
          Claim
        </Button>
      </div>
    </Card>
  )
}

interface ClaimValuesProps {
  availableToClaimTokens: Record<string, { amount: BigNumber, amountUsd: BigNumber, symbol: string }>
}

const ClaimValues: FC<ClaimValuesProps> = ({ availableToClaimTokens }) => {

  return (
    <TextWrap>
      {
        availableToClaimTokens && Object.keys(availableToClaimTokens).map(item => (
          <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextSymbol>{availableToClaimTokens[item].symbol}</TextSymbol>
            {formatBNWithCommas(availableToClaimTokens[item].amount, 2, 0, true)}
            {' '}
            (${formatBNWithCommas(availableToClaimTokens[item].amountUsd, 2, 0, true)})
          </div>
        ))
      }
    </TextWrap>
  )
}

export default Claim
