import React, {FC, memo, useMemo} from 'react'
import { Currency } from 'pulsex-sdk'
import { Button, Text } from 'sparkswap-uikit'
import styled from 'styled-components'
import { darken } from 'polished'
import _ from "lodash";
import { useCurrencyBalance } from '../../../../state/wallet/hooks'
import { TranslateString } from '../../../../utils/translateTextHelpers'
import { RowBetween } from '../../../../components/Row'
import NumericalInput from '../../../../components/NumericalInput'
import CurrencyLogo from '../../../../components/CurrencyLogo'
import { BridgeData } from '../../types/bridge'
import { useETHBalances } from "../../../../new/state/wallet/hooks";
import useWeb3 from '../../../../hooks/useWeb3'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`

interface BridgePanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  hideInput?: boolean
  readOnly?: boolean
  data?: BridgeData
  isNativeFrom?: boolean
}

const BridgePanel: FC<BridgePanelProps> = ({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = TranslateString(132, 'Input'),
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  hideInput = false,
  readOnly = false,
  data,
  isNativeFrom
}) => {
  const { account } = useWeb3()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const ethBalance = useETHBalances([account])

  const nativeBalance = useMemo(() => {
    if (_.isEmpty(ethBalance) || !account) return undefined;
    return ethBalance[account as any];
  },[ethBalance, account]);

  return (
    <InputPanel>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <Text fontSize="14px">{label}</Text>
              {account && (
                <Text onClick={onMax} fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? `Balance: ${isNativeFrom ? nativeBalance?.toSignificant(6) : selectedCurrencyBalance?.toSignificant(6)}`
                    : ''}
                </Text>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                readOnly={readOnly}
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMaxButton && label !== 'To' && (
                <Button onClick={onMax} size="sm" variant="text">
                  MAX
                </Button>
              )}
            </>
          )}
          <Aligner>
            {data?.token && <CurrencyLogo currency={data.token} size="24px" style={{ marginRight: '8px' }} />}
            <Text>{data?.token?.symbol}</Text>
          </Aligner>
        </InputRow>
      </Container>
    </InputPanel>
  )
}

export default memo(BridgePanel)
