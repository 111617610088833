import React, { FC } from 'react';
import styled from 'styled-components'

import Stats from './components/Stats'
import Info from './components/Info'
import Exchange from './components/Exchange'
import Claim from './components/Claim'
import MyNfts from './components/MyNfts'

import use404Info from './hooks/use404Info'

export const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: auto 330px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    grid-template-columns: auto 440px;
  }
`

export const Box = styled.div`
  width: 100%;
`

const Spark404: FC = () => {

  const { info: spark404Info , update404Info } = use404Info();

  return (
    <Box>
      <Stats spark404Info={spark404Info}/>
      <Inner>
        <div>
          <Info />
          <Claim spark404Info={spark404Info} update404Info={update404Info}/>
        </div>
        <Exchange />
      </Inner>
      <MyNfts spark404Info={spark404Info}/>
    </Box>
  )
}

export default Spark404;