import { Trade } from 'sparkswap-sdk'
import React, { memo } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex, Text } from 'sparkswap-uikit'
import { ThemeContext } from 'styled-components'
import { TYPE } from '../Shared'
import CurrencyLogo from '../CurrencyLogo'
import { RowFixed } from '../Row'
import QuestionHelper from '../QuestionHelper'

const { black: Black } = TYPE

export default memo(function SwapRoute({ trade }: { trade: Trade<any, any, any> }) {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <Flex
          px="1rem"
          py="0.5rem"
          my="0.5rem"
          style={{ background: `${theme?.colors.background}`, borderRadius: '16px' }}
          flexDirection='column'
          alignItems="flex-start"
        >
          <RowFixed>
            <Text fontSize="14px">Route</Text>
            <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
          </RowFixed>
          <Flex
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            style={{width: '100%', gap: '8px'}}
          >
            {trade.route.path.map((token, i, path) => {
              const isLastItem: boolean = i === path.length - 1
              return (
              // eslint-disable-next-line react/no-array-index-key

                <div key={token.name}>
                  <Flex my="0.5rem" alignItems="center" style={{ flexShrink: 0 }}>
                    <CurrencyLogo currency={token} size="1.5rem" />
                    <Black fontSize={14} color={theme?.colors.text} ml="0.5rem">
                      {token.symbol}
                    </Black>
                    {isLastItem ? null : <ChevronRight color={theme?.colors.textSubtle} />}
                  </Flex>
                </div>
              )
            })}
          </Flex>
        </Flex>
      )}
    </ThemeContext.Consumer>
  )
})
