enum NftClass {
  GOLD_TYPES_2_6,
  SILVER_TYPES_2_6,
  BRONZE_TYPES_2_6,
  PINK_TYPES_2_6,
  GOLD_TYPES_3_5,
  SILVER_TYPES_3_5,
  BRONZE_TYPES_3_5,
  PINK_TYPES_3_5,
  GOLD_TYPES_4,
  SILVER_TYPES_4,
  BRONZE_TYPES_4,
  PINK_TYPES_4,
  GOLD_TYPES_1,
  SILVER_TYPES_1,
  BRONZE_TYPES_1,
  PINK_TYPES_1
}

export const NFT_CLASS_NAME = {
  [NftClass.GOLD_TYPES_2_6]: 'Gold types 2,6',
  [NftClass.SILVER_TYPES_2_6]: 'Silver types 2,6',
  [NftClass.BRONZE_TYPES_2_6]: 'Bronze types 2,6',
  [NftClass.PINK_TYPES_2_6]: 'Pink types 2,6',
  [NftClass.GOLD_TYPES_3_5]: 'Gold types 3,5',
  [NftClass.SILVER_TYPES_3_5]: 'Silver types 3,5',
  [NftClass.BRONZE_TYPES_3_5]: 'Bronze types 3,5',
  [NftClass.PINK_TYPES_3_5]: 'Pink types 3,5',
  [NftClass.GOLD_TYPES_4]: 'Gold type 4',
  [NftClass.SILVER_TYPES_4]: 'Silver type 4',
  [NftClass.BRONZE_TYPES_4]: 'Bronze type 4',
  [NftClass.PINK_TYPES_4]: 'Pink type 4',
  [NftClass.GOLD_TYPES_1]: 'Gold type 1',
  [NftClass.SILVER_TYPES_1]: 'Silver type 1',
  [NftClass.BRONZE_TYPES_1]: 'Bronze type 1',
  [NftClass.PINK_TYPES_1]: ' Pink type 1',
}

export const REVENUE_DISTRIBUTOR = '0xcfD48605b830C8969B4042856B53F2c5Db5E96bc'
