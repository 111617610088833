import React, { FC, memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ChevronDownIcon, Text } from 'sparkswap-uikit'
import { CHAIN_ICON, CHAIN_NAME } from '../../../../constants/chain'

import { BridgeData } from '../../types/bridge'

import { Aligner, EmptyBlock, InputPanel, Select, SelectLabel } from '../../styles'

type Props = {
  label: string
  onOpenModal: () => void
  data: BridgeData
}

const StyledText = styled(Text)`
  font-size: 14px;
    
    ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 16px;
    }
`

const SelectButton: FC<Props> = ({ data, label, onOpenModal }) => {
  const isDisabledSelect = useMemo(() => data?.chains?.length <= 1, [data])

  const handleOpenModal = useCallback(() => {
    if (isDisabledSelect) return

    onOpenModal()
  }, [isDisabledSelect])

  return (
    <div>
      <SelectLabel>{label}</SelectLabel>
      <InputPanel>
        <Select selected={!!data.chain} className="open-currency-select-button" onClick={handleOpenModal}>
          <Aligner>
            <img src={CHAIN_ICON[data.chain]} alt="chain logo" width={25} style={{ marginRight: '8px' }} />
            <StyledText>{CHAIN_NAME[data.chain]}</StyledText>
            {isDisabledSelect ? <EmptyBlock /> : <ChevronDownIcon />}
          </Aligner>
        </Select>
      </InputPanel>
    </div>
  )
}

export default memo(SelectButton)
