import React, { useCallback, useContext, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { splitSignature } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, ETHER, Percent, WETH } from 'sparkswap-sdk'
import { Button, Flex, Heading, Text } from 'sparkswap-uikit'
import { ArrowDown, Plus } from 'react-feather'
import { RouteComponentProps } from 'react-router-dom'

import { BigNumber } from '@ethersproject/bignumber'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import { RowBetween, RowFixed } from '../../components/Row'

import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { ROUTER, ROUTER_ADDRESS } from '../../../constants'
import { useCurrency } from '../../hooks/Tokens'
import { usePairContract } from '../../hooks/useContract'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { StyledInternalLink, TYPE } from '../../components/Shared'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { ClickableText, Wrapper } from '../Pool/styleds'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { Dots } from '../../components/swap/styleds'
import { useBurnActionHandlers, useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'

import { Field } from '../../state/burn/actions'
import { useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import useWeb3 from '../../../hooks/useWeb3'
import ConnectWalletButton from '../../../components/ConnectWalletButton'

const { italic: Italic } = TYPE

const OutlineCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 16px;
  padding: 24px;
`

const Body = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library: provider } = useWeb3()
  const [tokenA, tokenB] = useMemo(
    () => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)],
    [currencyA, currencyB, chainId]
  )

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
        ? '<1'
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    ROUTER_ADDRESS[ROUTER.SPARKSWAP]
  )
  async function onAttemptToApprove() {
    if (!pairContract || !pair || !provider) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')
    // try to gather a signature for permission
    // const nonce = await pairContract.nonces(account)

    // const deadlineForSignature: number = Math.ceil(Date.now() / 1000) + deadline

    // const EIP712Domain = [
    //   { name: 'name', type: 'string' },
    //   { name: 'version', type: 'string' },
    //   { name: 'chainId', type: 'uint256' },
    //   { name: 'verifyingContract', type: 'address' },
    // ]
    // const domain = {
    //   name: 'Quickswap LPs',
    //   version: '1',
    //   chainId,
    //   verifyingContract: pair.liquidityToken.address,
    // }
    // const Permit = [
    //   { name: 'owner', type: 'address' },
    //   { name: 'spender', type: 'address' },
    //   { name: 'value', type: 'uint256' },
    //   { name: 'nonce', type: 'uint256' },
    //   { name: 'deadline', type: 'uint256' },
    // ]
    // const message = {
    //   owner: account,
    //   spender: ROUTER_ADDRESS,
    //   value: liquidityAmount.raw.toString(),
    //   nonce: nonce.toHexString(),
    //   deadline: deadlineForSignature,
    // }
    // const data = JSON.stringify({
    //   types: {
    //     EIP712Domain,
    //     Permit,
    //   },
    //   domain,
    //   primaryType: 'Permit',
    //   message,
    // })

    // library
    //   .send('eth_signTypedData_v4', [account, data])
    //   .then(splitSignature)
    //   .then((signature) => {
    //     setSignatureData({
    //       v: signature.v,
    //       r: signature.r,
    //       s: signature.s,
    //       deadline: deadlineForSignature,
    //     })
    //   })
    //   .catch((e) => {
    // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
    // if (e?.code !== 4001) {
    approveCallback()
    // }
    // })
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, val: string) => {
      setSignatureData(null)
      return _onUserInput(field, val)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((val: string): void => onUserInput(Field.LIQUIDITY, val), [onUserInput])
  const onCurrencyAInput = useCallback((val: string): void => onUserInput(Field.CURRENCY_A, val), [onUserInput])
  const onCurrencyBInput = useCallback((val: string): void => onUserInput(Field.CURRENCY_B, val), [onUserInput])

  // tx sending
  const addTransaction = useTransactionAdder()
  async function onRemove() {
    if (!chainId || !provider || !account) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }
    const router = getRouterContract(chainId, provider, account)

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB === ETHER
    const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH
    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[]
    let args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadlineFromNow,
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadlineFromNow,
        ]
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }
    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName, index) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((e) => {
            console.error(`estimateGas failed`, index, methodName, args, e)
            return undefined
          })
      )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
          })

          setTxHash(response.hash)
        })
        .catch((e: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(e)
        })
    }
  }

  function modalHeader() {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <AutoColumn gap="md" style={{ marginTop: '20px' }}>
            <RowBetween align="flex-end">
              <Text fontSize="24px">{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</Text>
              <RowFixed gap="4px">
                <CurrencyLogo currency={currencyA} size="24px" />
                <Text fontSize="24px" style={{ marginLeft: '10px' }}>
                  {currencyA?.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
            <RowFixed>
              <Plus size="16" color={theme?.colors.textSubtle} />
            </RowFixed>
            <RowBetween align="flex-end">
              <Text fontSize="24px">{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</Text>
              <RowFixed gap="4px">
                <CurrencyLogo currency={currencyB} size="24px" />
                <Text fontSize="24px" style={{ marginLeft: '10px' }}>
                  {currencyB?.symbol}
                </Text>
              </RowFixed>
            </RowBetween>

            <Italic fontSize={12} color={theme?.colors.textSubtle} textAlign="left" padding="12px 0 0 0">
              {`Output is estimated. If the price changes by more than ${allowedSlippage / 100
              }% your transaction will revert.`}
            </Italic>
          </AutoColumn>
        )}
      </ThemeContext.Consumer>
    )
  }

  function modalBottom() {
    return (
      <>
        <RowBetween>
          <Text color="textSubtle">{`LP ${currencyA?.symbol}/${currencyB?.symbol}`} Burned</Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin />
            <Text>{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text color="textSubtle">Price</Text>
              <Text>
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text>
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}
        <Button disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)} onClick={() => onRemove()}>
          Confirm
        </Button>
      </>
    )
  }

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER
  const oneCurrencyIsWETH = Boolean(
    chainId &&
    ((currencyA && WETH[chainId].equals(currencyA)) ||
      (currencyB && WETH[chainId].equals(currencyB)))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/vremove/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setSignatureData(null) // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <>
          <AppBody>
            <AddRemoveTabs adding={false} />
            <Wrapper>
              <TransactionConfirmationModal
                isOpen={showConfirm}
                onDismiss={handleDismissConfirmation}
                attemptingTxn={attemptingTxn}
                hash={txHash || ''}
                content={() => (
                  <ConfirmationModalContent
                    title="You will receive"
                    onDismiss={handleDismissConfirmation}
                    topContent={() => modalHeader()}
                    bottomContent={() => modalBottom()}
                  />
                )}
                pendingText={pendingText}
              />
              <AutoColumn gap="md">
                <Body>
                  <OutlineCard>
                    <AutoColumn>
                      <RowBetween>
                        <Text>Amount</Text>
                        <ClickableText
                          onClick={() => {
                            setShowDetailed(!showDetailed)
                          }}
                        >
                          {showDetailed ? 'Simple' : 'Detailed'}
                        </ClickableText>
                      </RowBetween>
                      <Flex justifyContent="start">
                        <Heading size='xxl' color='secondaryBright'>{formattedAmounts[Field.LIQUIDITY_PERCENT]}%</Heading>
                      </Flex>
                      {!showDetailed && (
                        <>
                          <Flex mb="8px">
                            <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} size={16}/>
                          </Flex>
                          <Flex justifyContent="space-around">
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                            >
                              25%
                            </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                            >
                              50%
                            </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                            >
                              75%
                            </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                            >
                              Max
                            </Button>
                          </Flex>
                        </>
                      )}
                    </AutoColumn>
                  </OutlineCard>
                </Body>
                {!showDetailed && (
                  <>
                    <ColumnCenter>
                      <ArrowDown size="16" color={theme?.colors.textSubtle} />
                    </ColumnCenter>
                    <Body>
                      <OutlineCard>
                        <AutoColumn gap="10px">
                          <RowBetween>
                            <Text fontSize="24px">{formattedAmounts[Field.CURRENCY_A] || '-'}</Text>
                            <RowFixed>
                              <CurrencyLogo currency={currencyA} style={{ marginRight: '12px' }} />
                              <Text fontSize="24px" id="remove-liquidity-tokena-symbol">
                                {currencyA?.symbol}
                              </Text>
                            </RowFixed>
                          </RowBetween>
                          <RowBetween>
                            <Text fontSize="24px">{formattedAmounts[Field.CURRENCY_B] || '-'}</Text>
                            <RowFixed>
                              <CurrencyLogo currency={currencyB} style={{ marginRight: '12px' }} />
                              <Text fontSize="24px" id="remove-liquidity-tokenb-symbol">
                                {currencyB?.symbol}
                              </Text>
                            </RowFixed>
                          </RowBetween>
                          {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                            <RowBetween style={{ justifyContent: 'flex-end' }}>
                              {oneCurrencyIsETH ? (
                                <StyledInternalLink
                                  to={`/remove/${currencyA === ETHER ? WETH[chainId].address : currencyIdA}/${currencyB === ETHER ? WETH[chainId].address : currencyIdB
                                  }`}
                                >
                                  Receive WPLS
                                </StyledInternalLink>
                              ) : oneCurrencyIsWETH ? (
                                <StyledInternalLink
                                  to={`/remove/${currencyA && currencyA.equals(WETH[chainId]) ? 'PLS' : currencyIdA
                                  }/${currencyB && currencyB.equals(WETH[chainId]) ? 'PLS' : currencyIdB}`}
                                >
                                  Receive PLS
                                </StyledInternalLink>
                              ) : null}
                            </RowBetween>
                          ) : null}
                        </AutoColumn>
                      </OutlineCard>
                    </Body>
                  </>
                )}
                <Body style={{ paddingBottom: '24px' }}>
                  {showDetailed && (
                    <>
                      <CurrencyInputPanel
                        value={formattedAmounts[Field.LIQUIDITY]}
                        onUserInput={onLiquidityInput}
                        onMax={() => {
                          onUserInput(Field.LIQUIDITY_PERCENT, '100')
                        }}
                        showMaxButton={!atMaxAmount}
                        disableCurrencySelect
                        currency={pair?.liquidityToken}
                        pair={pair}
                        id="liquidity-amount"
                      />
                      <ColumnCenter>
                        <ArrowDown size="16" color={theme?.colors.textSubtle} />
                      </ColumnCenter>
                      <CurrencyInputPanel
                        hideBalance
                        value={formattedAmounts[Field.CURRENCY_A]}
                        onUserInput={onCurrencyAInput}
                        onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                        showMaxButton={!atMaxAmount}
                        currency={currencyA}
                        label="Output"
                        onCurrencySelect={handleSelectCurrencyA}
                        id="remove-liquidity-tokena"
                      />
                      <ColumnCenter>
                        <Plus size="16" color={theme?.colors.textSubtle} />
                      </ColumnCenter>
                      <CurrencyInputPanel
                        hideBalance
                        value={formattedAmounts[Field.CURRENCY_B]}
                        onUserInput={onCurrencyBInput}
                        onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                        showMaxButton={!atMaxAmount}
                        currency={currencyB}
                        label="Output"
                        onCurrencySelect={handleSelectCurrencyB}
                        id="remove-liquidity-tokenb"
                      />
                    </>
                  )}
                  {pair && (
                    <div style={{ padding: '24px 0'}}>
                      <Flex justifyContent="space-between" mb="8px">
                        Price:
                        <div>
                          1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                          {currencyB?.symbol}
                        </div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div />
                        <div>
                          1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                          {currencyA?.symbol}
                        </div>
                      </Flex>
                    </div>
                  )}
                  <div style={{ position: 'relative' }}>
                    {!account ? (
                      <ConnectWalletButton fullWidth />
                    ) : (
                      <RowBetween>
                        <Button
                          onClick={() => onAttemptToApprove()}
                          variant={
                            approval === ApprovalState.APPROVED || signatureData !== null ? 'success' : 'primary'
                          }
                          disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                          mr="8px"
                        >
                          {approval === ApprovalState.PENDING ? (
                            <Dots>Approving</Dots>
                          ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                            'Approved'
                          ) : (
                            'Approve'
                          )}
                        </Button>
                        <Button
                          onClick={() => {
                            setShowConfirm(true)
                          }}
                          disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                          variant={
                            !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                              ? 'danger'
                              : 'primary'
                          }
                        >
                          {error || 'Remove'}
                        </Button>
                      </RowBetween>
                    )}
                  </div>
                </Body>
              </AutoColumn>
            </Wrapper>
          </AppBody>

          {pair ? (
            <AutoColumn style={{ minWidth: '20rem', marginTop: '1rem', maxWidth: 640, width: '100%' }}>
              <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
            </AutoColumn>
          ) : null}
        </>
      )}
    </ThemeContext.Consumer>
  )
}