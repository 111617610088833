import useWeb3 from '../../../hooks/useWeb3'
import { useState } from 'react'
import { web3SendTxWrap } from '../../../utils'
import { makeContract } from '../../Bridge/utils/contract.utils'
import { applyDecimals } from '../../Bridge/utils/formaters.utils'
//
// import showToast from '../../../components/Toast'
//
// import { TOAST_TYPE } from '../../../constants/toast.constants'
import bSparkAbi from '../../../constants/abis/bspark.json'
import { B_SPARK404, SPARK } from '../../../constants'


const useExchange = () => {
  const [loading, setLoading] = useState(false)
  const { library, chainId, account } = useWeb3()

  const onExchange = async (amount: string) => {
    try {
      setLoading(true)
      const contract = makeContract(library, bSparkAbi, B_SPARK404.address)

      const amountContract = applyDecimals(amount, SPARK.decimals).toFixed(0)

      const recipient = await web3SendTxWrap(
        contract,
        'exchange',
        [amountContract],
        { from: account },
        library,
        chainId,
      )

      // showToast(
      //   {
      //     title: `Burn ${amount}${CETO.symbol} to ${B_CETO.symbol}`,
      //     withLink: {
      //       chainId: chainId,
      //       hash: recipient.transactionHash,
      //     },
      //   },
      //   TOAST_TYPE.SUCCESS,
      //   {
      //     showProgressBar: true
      //   }
      // )
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    onExchange,
    loading,
  }
}

export default useExchange
