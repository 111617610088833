import { useEffect, useState } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { useBridgeContract } from 'hooks/useContract'
import useRefresh from './useRefresh'

import { getOrders, getToken } from '../utils/bridgeUtils'
import { toBN } from "../pages/Bridge/utils/formaters.utils";
import { makeBridgeContract, makeContract } from '../pages/Bridge/utils/contract.utils'

import { BridgeData, BridgeDataFrom } from '../pages/Bridge/types/bridge'
import CONFIG, { ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS, ROUTERS } from '../pages/Bridge/config'

import { ZERO_ADDRESS } from '../constants'
import useWeb3 from './useWeb3'

interface Config {
  tokenOnChainDestination: string
  feeTarget: string
  fee: string
  feeBase: string
  minAmount: string
  maxAmount: string
  directTransferAllowed: boolean
}
export const useTokenOld = (tokenId: number, targetChain: number) => {
  const [token, setToken] = useState({} as Config)
  const { chainId } = useWeb3()
  const bridgeContract = useBridgeContract()

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getToken(bridgeContract, tokenId, targetChain!)
        setToken({
          tokenOnChainDestination: res.tokenOnChainDestination.toString(),
          feeTarget: res.feeTarget.toString(),
          fee: res.fee.toString(),
          feeBase: res.feeBase.toString(),
          minAmount: res.minAmount.toString(),
          maxAmount: res.maxAmount.toString(),
          directTransferAllowed: Boolean(res.directTransferAllowed)
        } as Config)
      } catch (e) {
        console.error('useToken error', e)
      }
    }

    if (bridgeContract && tokenId > 0 && targetChain > 0) {
      fetch()
    }
  }, [bridgeContract, setToken, tokenId, targetChain, chainId])

  return token
}

type useTokenParams = {
  from: BridgeDataFrom
  to: BridgeData
}

export const useToken = ({ from, to }: useTokenParams) => {
  const [token, setToken] = useState({} as Config)
  const { libraryByChainId } = useWeb3()

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!from.token?.symbol || !to.token?.address) return
        const bridgeContract = makeBridgeContract(libraryByChainId(from.chain), from.chain)

        const res = await bridgeContract.methods.bridgeTokenToChainConfig(from.bridgeTokenId, to.chain).call()


        const resMin = res.minAmount.toString();
        const resMax = res.maxAmount.toString();

        let routerMin
        let routerMax

        const fromObj = CONFIG[from.chain][from.token?.symbol]
        const fromBridgeToken = fromObj.bridgeTokensMap[to.chain][to.token?.address]
        const fromRouterConfig = ROUTERS[fromBridgeToken.fromRouterType]
        const { fromSwapPath, fromRouterType } = fromBridgeToken

        const isNeedToUpdateMinMax = !fromBridgeToken.isBridge && !!fromSwapPath.length && toBN(resMin).gt(0)

        if (isNeedToUpdateMinMax) {
          const fromRouterContract = makeContract(libraryByChainId(from.chain), fromRouterConfig.abi, fromRouterConfig.address)

          if (ROUTER_TYPES_WITH_TOKEN_OUT_ADDRESS.includes(fromRouterType)) {
            const [resultMin, resultMax] = await Promise.all([
              fromRouterContract.methods.getAmountsIn(resMin, fromSwapPath, ZERO_ADDRESS).call(),
              fromRouterContract.methods.getAmountsIn(resMax, fromSwapPath, ZERO_ADDRESS).call()
            ])
            routerMin = resultMin[0]
            routerMax = resultMax[0]
          } else {
            const [resultMin, resultMax] = await Promise.all([
              fromRouterContract.methods.getAmountsIn(resMin, fromSwapPath).call(),
              fromRouterContract.methods.getAmountsIn(resMax, fromSwapPath).call()
            ])

            routerMin = resultMin[0]
            routerMax = resultMax[0]
          }
        }

        const minAmount = isNeedToUpdateMinMax ? routerMin : resMin;
        const maxAmount = isNeedToUpdateMinMax ? routerMax : resMax;

        setToken({
          tokenOnChainDestination: res.tokenOnChainDestination.toString(),
          feeTarget: res.feeTarget.toString(),
          fee: res.fee.toString(),
          feeBase: res.feeBase.toString(),
          minAmount,
          maxAmount,
          directTransferAllowed: Boolean(res.directTransferAllowed),
        } as Config)
      } catch (e) {
        console.error('Error in useToken:', e)
      }
    }

    if (from.bridgeTokenId > 0 && to.chain > 0 && from.token?.symbol && to.token?.address) {
      try {
        fetch()
      } catch (e) {
        console.log('useBridgeToken', e)
      }

    }
  }, [from.token?.symbol, from.bridgeTokenId, to.token?.address, from.chain, to.chain])

  return token
}

export const useVerifyReceiver = (receiver: string | null, chainId: number) => {
  const [isContract, setContract] = useState(false)
  const [provider, setProvider] = useState<Web3Provider>()
  const { libraryByChainId } = useWeb3()

  useEffect(() => {
    async function fetchProvider() {
      setProvider(await libraryByChainId(chainId));
    }
    if (chainId) {
      fetchProvider();
    }
  }, [chainId])

  useEffect(() => {
    async function fetchCode() {
      try {
        if (!receiver || !provider) {
          setContract(false);
          return;
        }

        const code = await provider.getCode(receiver)
        setContract(code !== '0x');
      } catch (e) {
        setContract(false);
      }
    }

    fetchCode()
  }, [receiver, provider])

  return !isContract;
}

export const useOrders = () => {
  const [orders, setOrders] = useState([] as any[])
  const [completedOrders, setCompletedOrders] = useState([] as any[])
  const bridgeContract = useBridgeContract()
  const { instantRefresh } = useRefresh()
  const { account } = useWeb3()

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await getOrders(bridgeContract)
        const newOrders = res.filter((o) => o.sender.toLowerCase() === account?.toLowerCase())

        setOrders(newOrders)
      } catch (e) {
        console.error(e)
      }
    }

    if (bridgeContract && account) {
      fetchOrders()
    }
  }, [bridgeContract, account, instantRefresh])

  useEffect(() => {
    for (let i = 0; i < orders.length; i++) {
      const pendingId = +orders[i].id
      const pendingDest = +orders[i].destination

      if (!completedOrders.find((o) => pendingId === +o.id && pendingDest === +o.destination)) {
        setCompletedOrders((prevCompletedOrders) => [...prevCompletedOrders, orders[i]])
      }
    }
  }, [orders, completedOrders])

  return { orders, completedOrders }
}
