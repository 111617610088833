import { useState, useEffect, useMemo } from 'react';

import { getNftImageFallbackLinks } from '../utils/ipfs.utils';

const TIMEOUT = 5e3;

function fetchWithTimeout(ms: number, ...[input, oInit]: Parameters<typeof fetch>) {
  const signal = AbortSignal.timeout(ms);
  if (oInit && oInit.signal) {
    throw new Error('fetchWithTimeout: signal already set');
  }

  const init = {
    ...oInit,
    signal,
  };
  return fetch(input, init);
}

const useIpfsFile = (cid: string) => {
  const [response, setResponse] = useState<Response>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [i, setI] = useState(0);

  const urls = useMemo(() => {
    if (!cid) {
      return [];
    }
    return getNftImageFallbackLinks(cid);
  }, [cid]);

  useEffect(() => {
    const url = urls[i];
    setLoading(true);
    fetchWithTimeout(TIMEOUT, url)
      .then((res) => {
        setResponse(res);
        return res;
      })
      .catch((err) => {
        if (i === urls.length - 1) {
          setError(err as Error);
        } else {
          setI(i + 1);
        }
      })
      .finally(() => {
        setLoading(false);
      });

  }, [urls, i]);

  return {
    response,
    loading,
    error,
  };
};

export default useIpfsFile;
