import { Currency, CurrencyAmount, ETHER, Token, WETH, Pulse } from 'sparkswap-sdk'

export function wrappedCurrency(currency: Currency | undefined, chainId: number | undefined): Token | undefined {
  // eslint-disable-next-line no-nested-ternary
  return chainId && currency?.isNative && WETH[Number(chainId)] ? WETH[Number(chainId)] : currency?.isToken ? currency : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount<Token> | CurrencyAmount<Pulse> | undefined,
  chainId: number | undefined
): CurrencyAmount<Token> | undefined {
  const token = currencyAmount ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? CurrencyAmount.fromRawAmount(token, currencyAmount.quotient) : undefined
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH[token.chainId])) return ETHER
  return token
}
