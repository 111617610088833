import Web3 from 'web3'

import { CHAIN_ID } from '../../../constants/chain'
import { BRIDGE_ABI } from '../../../constants/abis/bridge'
import { BRIDGE_ADDRESSES } from '../../../hooks/useContract'

export const makeContract = <T = any>(library: any, abi: any, address: string): T => {
  const web3 = new Web3(library.provider)
  return new web3.eth.Contract(abi, address) as unknown as T
}

export const makeBridgeContract = (library: any, chainId: CHAIN_ID) => {
  return makeContract(library, BRIDGE_ABI, BRIDGE_ADDRESSES[chainId])
}
