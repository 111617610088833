export const PATH_HOME = 'https://sparkswap.xyz/'
export const PATH_FARMS = 'https://sparkswap.xyz/#/farms'
export const PATH_HISTORY = '/history'
export const PATH_POOLS = '/pools'
export const PATH_SPARKLER = 'https://sparkswap.xyz/#/sparkler'
export const PATH_EXCHANGE = '/swap'
export const PATH_BRIDGE = '/bridge'
export const PATH_FARMS_HISTORY = `${PATH_FARMS}${PATH_HISTORY}`
export const PATH_SPARKLER_HISTORY = `${PATH_SPARKLER}${PATH_HISTORY}`
export const PATH_POOL_HISTORY = `${PATH_POOLS}${PATH_HISTORY}`

export const PATH_VAULTS = 'https://vaults.sparkswap.xyz'
export const PERPS_URL = 'https://perps.sparkswap.xyz'
export const DOCS_URL = 'https://empmoneyv2.gitbook.io/sparkswap/about/sparkswap-whitepaper'
export const CHART_URL = 'https://dexscreener.com/pulsechain/0x33208439e1B28B1d6fCfbB6334e9950027Ee3B52'
export const AUDIT_URL = 'https://fleek.ipfs.io/ipfs/bafybeiawdbrvhwwpvmuc6agcxsxpjrigv3bzjtfmwei2zftrjsf3gv4zlm'


export const NOT_FOUND = '/404'
