import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowDownIcon, Button, CardBody, IconButton, Text } from 'sparkswap-uikit'
import { ThemeContext } from 'styled-components'
import { ArrowDown } from 'react-feather'
import BigNumber from 'bignumber.js'
import { Trade } from 'pulsex-sdk'
import _ from 'lodash'

import { useToken, useVerifyReceiver } from 'hooks/useBridge'
import { useBridgeContract } from 'hooks/useContract'
import { useBridgeCallback } from 'hooks/useBridgeCallback'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import tryParseAmount  from 'state/bridge/hooks'

import { maxAmountSpend } from 'utils/maxAmountSpend'
import { TranslateString } from 'utils/translateTextHelpers'

import Loader from 'components/Loader'
import { AutoColumn } from 'components/Column'
import PageHeader from 'components/PageHeader'
import { LinkStyledButton } from 'components/Shared'
import { AutoRow, RowBetween } from 'components/Row'
import ProgressSteps from 'components/ProgressSteps'
import AddressInputPanel from 'components/AddressInputPanel'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ArrowWrapper, BottomGrouping, Wrapper } from 'components/swap/styleds'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import useBridgeData from './hooks/useBridgeData'
import { useBalance, useETHBalances } from '../../new/state/wallet/hooks'

import AppBody, { Box } from '../AppBody'
import BridgePanel from './components/BridgePanel'
import SelectToken from './components/SelectToken'
import SelectButton from './components/SelectButton'
import ChainChangeModal from './components/ChainChangeModal'

import { useTokenBalance } from '../../state/wallet/hooks'
import useBridgeActions, { EstimateParams } from './hooks/useBridgeActions'

import { calculateFormattedAmount, convertToWei } from './helpers/bridge'

import { applyDecimals, formatBNWithCommas, toBN } from './utils/formaters.utils'

import { CHAIN_ID, CHAIN_NAME } from '../../constants/chain'

import { GridRow } from './styles'
import useWeb3 from '../../hooks/useWeb3'
import { SPARK, TOKEN_SYMBOL, TOKENS } from '../../constants'
import { BRIDGE_ADDRESS_BSC } from '../../constants/abis/bridge'
import { isAddressesEqual } from '../../utils'

const Bridge = () => {
  const contract = useBridgeContract()
  const { account, chainId } = useWeb3()
  const { from, to, onSwitchChains } = useBridgeData()
  const { onEstimate } = useBridgeActions()

  const [{ showConfirm, swapErrorMessage, attemptingTxn, txHash }, setBridgeState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<any, any, any> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const {
    balance: bscDaiBalance,
    updateBalance
  } = useBalance(TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address, BRIDGE_ADDRESS_BSC, CHAIN_ID.BSC);

  const [inputValue, setInputValue] = useState('')
  const [estimation, setEstimation] = useState<BigNumber>(toBN(0))
  const [recipient, setRecipient] = useState<string | null>(null)

  const [fromModalOpen, setFromModalOpen] = useState(false)
  const [toModalOpen, setToModalOpen] = useState(false)

  const [approvalSubmitted, setApprovalSubmitted] = useState(false)

  const tokenFromBalance = useTokenBalance(account ?? undefined, from.token ?? undefined)
  const ethBalance = useETHBalances([account])


  const isDisableBscDaiBridge = useMemo(() => {
    if (to?.chain === CHAIN_ID.BSC && isAddressesEqual(to?.token?.address, TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].address)) {
      if (toBN(estimation).gt(0)) {
        const allowedDaiValue = toBN(bscDaiBalance, TOKENS[CHAIN_ID.BSC][TOKEN_SYMBOL.DAI].decimals).times(0.9)
        return estimation.gt(allowedDaiValue)
      }
    }
    return false
  }, [bscDaiBalance, estimation, to]);


  const nativeBalance = useMemo(() => {
    if (_.isEmpty(ethBalance) || !account) return undefined
    return ethBalance[account as any]
  }, [ethBalance, account])

  const parsedTokenFromAmount = useMemo(
    () => tryParseAmount(inputValue, from.token ?? undefined),
    [inputValue, from.token],
  )

  const [approval, approveCallback] = useApproveCallback(parsedTokenFromAmount, contract ? contract.address : undefined)

  const handleChangeRecipient = useCallback((value: string | null) => {
    setRecipient(value)
  }, [])

  const reveiverVerified = useVerifyReceiver(recipient, to.chain)

  const handleChange = useCallback((value: string) => {
    setInputValue(value)
  }, [])

  const handleDismissModals = useCallback(() => {
    setToModalOpen(false)
    setFromModalOpen(false)
  }, [])

  const handleSwitchChain = async () => {
    setApprovalSubmitted(false) // reset 2 step UI for approvals
    await onSwitchChains()
    handleChange('')
  }

  const handleApprove = async () => {
    try {
      await approveCallback()
    } catch (e) {
      console.log(e, 'e')
    }
  }

  const { isNative } = from

  const showApproveFlow = useMemo(
    () =>
      (approval === ApprovalState.NOT_APPROVED ||
        approval === ApprovalState.PENDING ||
        (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
      !isNative,
    [approval, approvalSubmitted, isNative],
  )

  const maxAmountInput = useMemo(
    () => (isNative ? nativeBalance : maxAmountSpend(tokenFromBalance)),
    [tokenFromBalance, nativeBalance, isNative],
  )

  const atMaxAmountInput = useMemo(
    () => !!(maxAmountInput && parsedTokenFromAmount?.equalTo(maxAmountInput)),
    [maxAmountInput, parsedTokenFromAmount],
  )

  const handleConfirmDismiss = useCallback(() => {
    setBridgeState((prevState) => ({
      ...prevState,
      showConfirm: false,
    }))

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      handleChange('')
    }
  }, [txHash, setBridgeState])

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      handleChange(maxAmountInput.toExact())
    }
  }, [maxAmountInput])

  const token = useToken({
    from,
    to,
  })

  // const feePercent = useMemo(() => token && +token.fee / 100, [token])

  const { minAmount, maxAmount } = token
  const inputDecimals = from.token?.decimals
  const outputDecimals = to.token?.decimals

  const min = useMemo(
    () => !!minAmount && !!inputDecimals && calculateFormattedAmount(minAmount, inputDecimals),
    [minAmount, inputDecimals, chainId],
  )
  const max = useMemo(
    () => !!maxAmount && !!inputDecimals && calculateFormattedAmount(maxAmount, inputDecimals),
    [maxAmount, inputDecimals, chainId],
  )

  const withinMinMax = useMemo(() => {
    const amountWei = convertToWei(inputValue, inputDecimals)

    const isTokenValid = minAmount && maxAmount
    const isAmountValid = !!(isTokenValid && amountWei && amountWei.gte(minAmount) && amountWei.lte(maxAmount))

    return isTokenValid && isAmountValid
  }, [inputValue, inputDecimals, minAmount, maxAmount])

  const isValid = useMemo(() => {
    const estimateValid = !!(estimation && outputDecimals && applyDecimals(estimation, outputDecimals))
    const recipientValid = !recipient || reveiverVerified

    return withinMinMax && estimateValid && recipientValid
  }, [withinMinMax, estimation, outputDecimals, reveiverVerified, recipient])

  const depositMoreThanBalance = useMemo(
    () => !!(maxAmountInput && inputValue && new BigNumber(inputValue).gt(maxAmountInput.toExact())),
    [maxAmountInput, inputValue],
  )

  const { callback: bridgeCallback } = useBridgeCallback({
    from,
    to,
    amount: inputValue,
    recipientAddressOrName: recipient,
  })

  const handleBridge = useCallback(() => {
    if (!bridgeCallback) return
    if (!from.chain || !chainId || from.chain !== +chainId) {
      setBridgeState((prevState) => ({
        ...prevState,
        attemptingTxn: false,
        swapErrorMessage: 'Wrong network',
        txHash: undefined,
        showConfirm: true,
      }))
      return
    }
    setBridgeState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
      showConfirm: true,
    }))
    bridgeCallback()
      .then((hash) => {
        setBridgeState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }))
      })
      .catch((error) => {
        setBridgeState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
          showConfirm: true,
        }))
      })
  }, [bridgeCallback, from.chain, chainId])

  const pendingText = useMemo(
    () => `Sending ${inputValue} ${from?.token?.symbol} to ${CHAIN_NAME[to.chain]}`,
    [inputValue, from, to],
  )

  const errorContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={handleConfirmDismiss} message="Transaction denied" />
      ) : (
        <TransactionErrorContent onDismiss={handleConfirmDismiss} message="Unknown" />
      ),
    [swapErrorMessage, handleConfirmDismiss],
  )

  const formatEstimation = useMemo(() => {
    if (!withinMinMax) {
      return '-'
    }

    const showEstimation = toBN(inputValue).gt(0) && estimation.gt(0)

    const value = showEstimation ? estimation : 0

    return formatBNWithCommas(value, 2, undefined, true)
  }, [estimation, withinMinMax, inputValue])

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval])

  const debounceEstimate = useRef(
    _.debounce(async (params: EstimateParams) => {
      const result = await onEstimate(params)
      setEstimation(result)
    }, 400),
  ).current

  useEffect(() => {
    if (withinMinMax) {
      (async () => {
        await debounceEstimate({
          amount: inputValue,
          from,
          to,
        })
      })()
    }
  }, [inputValue, withinMinMax])

  return (
    <AppBody>
      <Wrapper id="swap-page">
        <TransactionConfirmationModal
          isOpen={showConfirm}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          pendingText={pendingText}
          onDismiss={handleConfirmDismiss}
          content={errorContent}
        />
        <PageHeader hideSettings hideHistory title="Sparkswap Bridge" description="Cross-Chain Token Bridge" />
        <CardBody>
          <GridRow>
            <SelectButton data={from} onOpenModal={() => setFromModalOpen(true)} label="Network" />
            <SelectToken label="Token" data={from} onClearInput={handleChange} />
          </GridRow>

          <AutoColumn gap="md" style={{ position: 'relative' }}>
            <BridgePanel
              label={TranslateString(76, 'From')}
              value={inputValue}
              showMaxButton={!atMaxAmountInput}
              currency={from.token}
              onUserInput={handleChange}
              onMax={handleMaxInput}
              data={from}
              isNativeFrom={isNative}
            />
            {min && max && (
              <AutoRow justify="space-between">
                <Text fontSize="12px" color="text" style={{ textAlign: 'center' }}>
                    Min: {min}
                </Text>
                <Text fontSize="12px" color="text" style={{ textAlign: 'center' }}>
                    Max: {max}
                </Text>
              </AutoRow>
            )}
          </AutoColumn>

          <AutoColumn style={{ paddingTop: '16px' }}>
            <ArrowWrapper clickable style={{ margin: 'auto auto' }}>
              <IconButton variant="tertiary" onClick={handleSwitchChain} style={{ borderRadius: '50%' }} size="sm">
                <ArrowDownIcon color="text" width="24px" />
              </IconButton>
            </ArrowWrapper>
          </AutoColumn>

          <AutoColumn>
            <GridRow>
              <SelectButton data={to} onOpenModal={() => setToModalOpen(true)} label="Network" />
              <SelectToken label="Token" data={to} onClearInput={handleChange} />
            </GridRow>
            <BridgePanel
              label="You will receive"
              data={to}
              value={formatEstimation}
              currency={from.token}
              onUserInput={handleChange}
              readOnly
              hideBalance
              showMaxButton={false}
            />
          </AutoColumn>

          <AutoColumn style={{ paddingTop: '16px' }}>
            <AutoColumn justify="space-between">
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                {recipient === null ? (
                  <LinkStyledButton id="add-recipient-button" onClick={() => handleChangeRecipient('')}>
                      + Change recipient (optional)
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>

            {recipient !== null ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ThemeContext.Consumer>
                      {(theme) => <ArrowDown size="16" color={theme?.colors.textSubtle} />}
                    </ThemeContext.Consumer>
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => handleChangeRecipient(null)}>
                      - Reset recipient
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={handleChangeRecipient} />
              </>
            ) : null}
          </AutoColumn>

          {
            isDisableBscDaiBridge
              ? (
                <BottomGrouping>
                  <Button
                    id="swap-button"
                    variant="primary"
                    fullWidth
                    disabled
                  >
                    Insufficient liquidity. Please wait for rebalancing
                  </Button>
                </BottomGrouping>
              )
              : (
                <BottomGrouping>
                  {!account ? (
                    <ConnectWalletButton fullWidth />
                  ) : showApproveFlow ? (
                    <RowBetween>
                      <Button
                        onClick={handleApprove}
                        disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted || depositMoreThanBalance}
                        style={{ width: '48%' }}
                        variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          `Approve ${from?.token?.symbol}`
                        )}
                      </Button>
                      <Button
                        onClick={() => handleBridge()}
                        style={{ width: '48%' }}
                        id="swap-button"
                        disabled={approval !== ApprovalState.APPROVED || !isValid}
                        variant="primary"
                      >
                        Send
                      </Button>
                    </RowBetween>
                  ) : (
                    <Button
                      onClick={() => handleBridge()}
                      id="swap-button"
                      variant="primary"
                      fullWidth
                      disabled={!isValid || depositMoreThanBalance}
                    >
                      Send
                    </Button>
                  )}
                  {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
                </BottomGrouping>
              )
          }
        </CardBody>
      </Wrapper>

      {fromModalOpen && (
        <ChainChangeModal
          data={from}
          title="From Chain"
          isOpen={fromModalOpen}
          onClearInput={handleChange}
          onDismiss={handleDismissModals}
        />
      )}
      {toModalOpen && (
        <ChainChangeModal
          data={to}
          title="To Chain"
          isOpen={toModalOpen}
          onClearInput={handleChange}
          onDismiss={handleDismissModals}
        />
      )}
    </AppBody>
  )
}

export default Bridge
