import { Currency } from '@pulsex/sdk-core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../../assets/images/PLS.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'
import { DAI_TOKEN, EMP_TOKEN, SPARK_ADDRESS, USDEX_ADDRESSES } from '../../../constants'
import { CHAIN_ID } from "../../../constants/chain";

const getTokenLogoURL = (address: string) => {
  switch (address.toLowerCase()) {
  case EMP_TOKEN[CHAIN_ID.BSC].address.toLowerCase(): case EMP_TOKEN[CHAIN_ID.PULSE_CHAIN].address.toLowerCase(): // EMP
    return `https://emp.money/logo.png`
  case SPARK_ADDRESS.toLowerCase():
    return `https://ipfs.fleek.co/ipfs/bafybeibu3uevnvt4zj4lub2j3naabn3fkbqwiuhyf76ebbl6hg4cko732q`
  case USDEX_ADDRESSES[CHAIN_ID.PULSE_CHAIN].toLowerCase():
    return `https://fleek.ipfs.io/ipfs/bafybeid3jvkv6c3jgokdk6uncst3ru23tzq73yyloei5vbduj46ecwbrwq`
  case DAI_TOKEN[CHAIN_ID.PULSE_CHAIN].address.toLowerCase():
    return `https://fleek.ipfs.io/ipfs/bafybeieu36uvp3wgss2fbkwsbqwrpa24i3fzyocueax4yjrvkh2cegzmji`
  default:
    return `https://tokens.app.pulsex.com/images/tokens/${address}.png`
    // return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`
  }
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return []

    if (currency?.isToken) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, `/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address)]
      }

      return [`/images/coins/${currency?.symbol ?? 'token'}.png`, getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency?.isNative) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  return (currency as any)?.symbol ? (
    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
